import { Errors } from '~/project-settings/graphql/types';

const createSshKey = (response: any): Errors => {
  const hostnameErrorMessage =
    'Invalid hostname. Hostnames must start and end with a letter or number. Hostnames may contain only letters, numbers, hyphens, and periods.';
  if (response.message && response.message === hostnameErrorMessage) {
    return {
      errors: [
        { name: 'hostname', type: 'server', message: hostnameErrorMessage },
      ],
    };
  }
  if (response.message) {
    return {
      errors: [
        { name: 'privateKey', type: 'server', message: response.message },
      ],
    };
  }
  return { errors: [] };
};

export default createSshKey;
