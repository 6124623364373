import * as React from 'react';

import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarSearch,
  KBarResults,
  useMatches,
  ActionImpl,
  ActionId,
} from 'kbar';
import useOrganizationAction from './useOrganizationAction';
import { useCollaborationActions } from '~/components/CmdK/useCollaborationActions';
import { useProjectsActions } from '~/components/CmdK/useProjectsActions';
import useDocsActions from './useDocsAction';
import {
  AddIcon,
  SettingsIcon,
  IncidentOperationalIcon,
} from '@circleci/compass';
import useCurrentProjectAction from './useCurrentProjectAction';
import { trackAction } from '@circleci/analytics';

const searchStyle = {
  padding: '12px 16px',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
  outline: 'none',
  border: 'none',
  background: 'rgb(252 252 252)',
  color: 'rgb(28 28 29)',
};

const animatorStyle = {
  maxWidth: '600px',
  width: '100%',
  background: 'rgb(252 252 252)',
  color: 'rgb(28 28 29)',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)',
};

const groupNameStyle = {
  padding: '8px 16px',
  fontSize: '10px',
  textTransform: 'uppercase' as const,
  opacity: 0.5,
};

const actions = [
  {
    id: 'status',
    name: 'Status',
    shortcut: ['s'],
    icon: <IncidentOperationalIcon />,
    keywords: 'status',
    section: 'Help',
    perform: () => window.open('https://status.circleci.com/'),
  },
  {
    id: 'new-organization',
    name: 'create new organization',
    icon: <AddIcon />,
    shortcut: ['n o'],
    keywords: 'create new organization',
    section: 'Actions',
    perform: () => (window.location.href = '/create-organization'),
  },
  {
    id: 'user-settings',
    name: 'user settings',
    icon: <SettingsIcon />,
    shortcut: ['u'],
    keywords: 'user settings',
    section: 'User Settings',
  },
  {
    id: 'user-notifications',
    name: 'notifications',
    keywords: 'user notifications',
    parent: 'user-settings',
    section: 'User Settings',
    perform: () => (window.location.href = '/settings/user/notifications'),
  },
  {
    id: 'user-privacy',
    name: 'privacy',
    keywords: 'user privacy',
    parent: 'user-settings',
    section: 'User Settings',
    perform: () => (window.location.href = '/settings/user/privacy'),
  },
  {
    id: 'user-tokens',
    name: 'tokens',
    keywords: 'user tokens',
    parent: 'user-settings',
    section: 'User Settings',
    perform: () => (window.location.href = '/settings/user/tokens'),
  },
  {
    id: 'user-org-plans',
    name: 'organization plans',
    keywords: 'user organization plans',
    parent: 'user-settings',
    section: 'User Settings',
    perform: () => (window.location.href = '/settings/user/plans'),
  },
  {
    id: 'user-beta',
    name: 'join beta program',
    keywords: 'user beta program',
    parent: 'user-settings',
    section: 'User Settings',
    perform: () => (window.location.href = '/settings/user/beta'),
  },
];

interface Props {
  children: React.ReactNode;
}

const RenderResults = () => {
  const { results } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <div style={groupNameStyle}>{item}</div>
        ) : (
          <ResultItem
            action={item}
            active={active}
            // currentRootActionId={rootActionId}
          />
        )
      }
    />
  );
};

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId?: ActionId;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId,
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <div
        ref={ref}
        style={{
          padding: '12px 16px',
          background: active ? 'rgba(0 0 0 / 0.05)' : 'white',
          borderLeft: `2px solid ${active ? 'rgb(28 28 29)' : 'transparent'}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            fontSize: 14,
          }}
        >
          {action.icon && action.icon}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 1,
                        marginRight: 8,
                      }}
                    >
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span style={{ fontSize: 12 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: 'grid', gridAutoFlow: 'column', gap: '4px' }}
          >
            {action.shortcut.map((sc) => (
              <kbd
                key={sc}
                style={{
                  padding: '4px 6px',
                  background: 'rgba(0 0 0 / .1)',
                  borderRadius: '4px',
                  fontSize: 14,
                }}
              >
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  },
);

const CommandBar = () => {
  useCollaborationActions();
  useOrganizationAction();
  useDocsActions();
  useProjectsActions();
  useCurrentProjectAction();

  return (
    <KBarPortal>
      <KBarPositioner style={{ zIndex: 10 }}>
        <KBarAnimator style={animatorStyle}>
          <KBarSearch style={searchStyle} />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

const isFirefoxBrowser = () =>
  navigator?.userAgent.toLowerCase().includes('firefox');

// Default shortcut is $mod+k, however this colides with Firefox shortcut to search the web
// we make this as function because window is not defined when Next render the page in the
// backend
const getOptions = () =>
  isFirefoxBrowser() ? { toggleShortcut: '$mod+Shift+k' } : {};

export const CmdK = ({ children }: Props) => {
  return (
    <KBarProvider
      actions={actions}
      options={{
        ...getOptions(),
        callbacks: {
          onOpen: () => trackAction('cmdk-opened'),
          onClose: () => trackAction('cmdk-closed'),
        },
      }}
    >
      <CommandBar />
      {children}
    </KBarProvider>
  );
};
