import * as React from 'react';
import { Action, Priority, useKBar } from 'kbar';
import router, { useRouter } from 'next/router';
import { VCSType } from '~/graphql/shim/types';
import useProjectBranches from '~/hooks/project/useProjectBranches';
import { FileSettingsIcon } from '@circleci/compass';

export default function useCurrentProjectAction() {
  const { query } = useRouter();
  const vcsType = query.vcsType as VCSType;
  const orgName = query.username as string;
  const project = query.project as string;
  const { branches } = useProjectBranches({
    vcsType,
    username: orgName,
    project,
    skip: !project,
  });
  const branchActions = branches?.length
    ? branches?.filter(Boolean).map((branch) => {
        const items: Action[] = [
          {
            id: `${project}/${branch}`,
            name: branch,
            parent: 'currentproject',
            keywords: branch,
            section: 'Branches',
            perform: () =>
              router.push(
                `/pipelines/${vcsType}/${orgName}/${project}/?branch=${branch}`,
              ),
          },
          {
            id: `${project}/${branch}`,
            name: 'Config',
            subtitle: 'edit',
            parent: `${project}/${branch}`,
            icon: <FileSettingsIcon />,
            keywords: `${project}-${branch}-config`,
            section: 'Config',
            perform: () =>
              router.push(
                `/pipelines/${vcsType}/${orgName}/${project}/config/?branchName=${branch}`,
              ),
          },
        ];
        return items;
      })
    : [];

  const { query: kbarQuery } = useKBar();
  const actions = [];
  if (project) {
    actions.push(
      {
        id: `currentproject-Settings`,
        parent: 'currentproject',
        name: 'Project Settings',
        shortcut: [],
        keywords: 'settings',
        section: 'Miscellaneous',
        perform: () =>
          router.push(`/settings/project/${vcsType}/${orgName}/${project}`),
      },
      {
        id: `currentproject-Insights`,
        parent: 'currentproject',
        name: 'Project Insights',
        shortcut: [],
        keywords: 'insights',
        section: 'Miscellaneous',
        perform: () =>
          router.push(`/insights/${vcsType}/${orgName}/${project}`),
      },
    );

    actions.push(branchActions.filter(Boolean).flat());

    const rootprojectAction = {
      id: 'currentproject',
      name: project || '',
      parent: 'currentorganization',
      priority: Priority.HIGH,
      shortcut: ['c p'],
      keywords: project || '',
      section: 'Current Project',
    };

    kbarQuery.registerActions(
      [rootprojectAction, ...actions.flat()].filter(Boolean),
    );
  }
}
