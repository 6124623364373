import { client } from '@circleci/web-ui-data';

export interface APIMe {
  analytics_id: string;
  selected_email: string;
}

const fetchMe = async (fetch: any, apiKey?: string): Promise<APIMe> => {
  const pathname = `/api/v1.1/me`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });

  return await response.json();
};

export default fetchMe;
