import {
  APISettings,
  APIProject,
  APICollaboration,
  APIOrganizationsSettings,
  APIProjectsSettings,
  NotificationSettings,
  OrganizationNotificationSettings,
  ProjectNotificationSettings,
  NonMemberOrganizationNotificationSettings,
} from '~/user-settings/graphql/shim/types';
import * as R from 'ramda';

const mapProjectWithPrefs = (
  filtered: APIProject[],
  projectPrefs: APIProjectsSettings,
): ProjectNotificationSettings[] => {
  return R.map((project: APIProject) => {
    const projWithPrefs = { ...project, ...projectPrefs[project.vcs_url] };

    return {
      reponame: projWithPrefs.reponame,
      vcsUrl: projWithPrefs.vcs_url,
      emails: projWithPrefs.emails || 'default',
    };
  }, filtered);
};

const getMemberProjectWithPrefs = (
  orgName: string,
  vscType: string,
  allProjects: APIProject[],
  projectPrefs: APIProjectsSettings,
) => {
  const filtered = R.filter(
    (project) =>
      R.and(
        R.equals(project.username, orgName),
        R.equals(project.vcs_type, vscType),
      ),
    allProjects,
  );
  return mapProjectWithPrefs(filtered, projectPrefs);
};

const getNonMemberProjectWithPrefs = (
  orgName: string,
  vcsType: string,
  allProjects: APIProject[],
  projectPrefs: APIProjectsSettings,
) => {
  const filtered = R.filter(
    (project) =>
      R.and(
        R.equals(project.username, orgName),
        R.equals(project.vcs_type, vcsType),
      ),
    allProjects,
  );
  return mapProjectWithPrefs(filtered, projectPrefs);
};

const getOrgPrefsEmail = (
  organizationPrefs: APIOrganizationsSettings,
  org: Pick<APICollaboration, 'vcs_type' | 'name'>,
) =>
  R.hasPath([org.vcs_type, org.name], organizationPrefs)
    ? organizationPrefs?.[org.vcs_type]?.[org.name].email
    : 'Default';

const reshapeMemberOrgs = (
  organizationPrefs: APIOrganizationsSettings,
  projectPrefs: APIProjectsSettings,
  allProjects: APIProject[],
  memberOrganizations: APICollaboration[],
): OrganizationNotificationSettings[] =>
  R.map((org): OrganizationNotificationSettings => {
    return {
      name: org.name,
      email: getOrgPrefsEmail(organizationPrefs, org) as any,
      avatarUrl: org.avatar_url,
      vcsType: org.vcs_type,
      projects: getMemberProjectWithPrefs(
        org.name,
        org.vcs_type,
        allProjects,
        projectPrefs,
      ),
    };
  }, memberOrganizations);

const reshapeNonMemberOrgs = (
  organizationPrefs: APIOrganizationsSettings,
  projectPrefs: APIProjectsSettings,
  allProjects: APIProject[],
  nonMemberOrganizations: Pick<APICollaboration, 'name' | 'vcs_type'>[],
): NonMemberOrganizationNotificationSettings[] =>
  R.map((org): NonMemberOrganizationNotificationSettings => {
    return {
      name: org.name,
      email: getOrgPrefsEmail(organizationPrefs, org) as any,
      vcsType: org.vcs_type,
      projects: getNonMemberProjectWithPrefs(
        org.name,
        org.vcs_type,
        allProjects,
        projectPrefs,
      ),
    };
  }, nonMemberOrganizations);

const getNonMemberProjects = ({
  memberOrganizations,
  projects,
}: {
  memberOrganizations: APICollaboration[];
  projects: APIProject[];
}) =>
  R.reject(
    (project: any) =>
      R.any(
        (org) =>
          project?.username === org.name && project?.vcs_type === org.vcs_type,
        memberOrganizations,
      ),
    projects,
  );

const getNonMemberOrganizations = (nonMemberProjects: any) =>
  R.uniq(
    R.map(
      (project) => ({
        vcs_type: project.vcs_type,
        name: project.username,
      }),
      nonMemberProjects,
    ),
  );

export default (
  {
    all_emails,
    selected_email,
    basic_email_prefs,
    organization_prefs,
    projects: project_prefs,
  }: APISettings,
  all_projects: APIProject[],
  member_organizations: APICollaboration[],
): NotificationSettings => {
  const nonMemberProjects = getNonMemberProjects({
    projects: all_projects,
    memberOrganizations: member_organizations,
  });
  const nonMemberOrganizations = getNonMemberOrganizations(nonMemberProjects);

  return {
    allEmails: all_emails,
    selectedEmail: selected_email,
    basicEmailPrefs: basic_email_prefs,
    memberOrganizations: reshapeMemberOrgs(
      organization_prefs,
      project_prefs,
      all_projects,
      member_organizations,
    ),
    nonMemberOrganizations: reshapeNonMemberOrgs(
      organization_prefs,
      project_prefs,
      nonMemberProjects,
      nonMemberOrganizations,
    ),
  };
};
