import * as React from 'react';

import { AlertContent, AlertTitle, ErrorAlert, Link } from '@circleci/compass';
import { css } from '@emotion/core';

import { mediaDesktop } from '~/plans-and-payments/components/shared/viewPorts';
import styled from '~/styled';

const StyledAlert = styled(ErrorAlert)<{}>(({ theme }) => {
  return css`
    margin: 0 auto 2rem;
    width: 100%;
    ${mediaDesktop} {
      // 100% width, minus both the left and right margins
      max-width: calc(100% - ${theme.space.space12}px);
    }
  `;
});

export const PendingPaymentNotice = (): React.ReactElement => {
  return (
    <StyledAlert>
      <AlertTitle>
        There was a problem processing your Prepaid Billing payment.
      </AlertTitle>
      <AlertContent>
        You can contact us at&nbsp;
        <Link href="mailto:billing@circleci.com">billing@circleci.com</Link>
      </AlertContent>
    </StyledAlert>
  );
};
