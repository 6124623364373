import React, { useContext } from 'react';

import Cookies from 'js-cookie';

export interface ApiKeyContext {
  apiKey?: string | null;
}

const defaultContext: ApiKeyContext = {
  apiKey: null,
};
export const ApiKeyContext = React.createContext<ApiKeyContext>(defaultContext);
export const useApiContext = () => useContext(ApiKeyContext);

const ApiContextProvider: React.FC = ({ children }) => {
  let apiKey;

  try {
    apiKey =
      localStorage.getItem('CIRCLE_TOKEN') || Cookies.get('circle-token');
  } catch (e) {
    /**
     * We do nothing here because localStorage failures are specific to browser configuration.
     * We don't want to stop the app because only local dev users are expected to have a token.
     */
    apiKey = null;
  }

  const apiContext: ApiKeyContext = {
    apiKey,
  };

  return (
    <ApiKeyContext.Provider value={apiContext}>
      {children}
    </ApiKeyContext.Provider>
  );
};

export default ApiContextProvider;
