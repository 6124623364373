import {
  APIJobRerunFailedTestsMetricsResponse,
  APIV2Workflow,
  APIWorkflowRerunFailedTestsMetricsResponse,
  JobRerunFailedTestsMetrics,
  PipelineWorkflow,
  PipelineActor,
  WorkflowRerunFailedTestsMetrics,
} from '~/graphql/shim/types';
import convertVcsType from '~/utils/vcs/convertVcsType';

const systemActors: Record<string, PipelineActor> = {
  'f7a4b427-ee60-4f69-bfba-7b0683326c5b': { name: 'CircleCI Maintenance' },
  '7f8fddd2-90cf-452a-9afa-2bdbafa4c3c5': { name: 'CircleCI Automation' },
};

const mapToWorkflow = (workflow: APIV2Workflow): PipelineWorkflow => {
  return {
    id: workflow.id,
    name: workflow.name,
    status: workflow?.status?.toUpperCase(),
    createdAt: workflow.created_at,
    stoppedAt: workflow.stopped_at,
    pipelineId: workflow.pipeline_id,
    // pipeline numbers start from 1, so 0 is not valid
    pipelineNumber: workflow.pipeline_number
      ? String(workflow.pipeline_number)
      : undefined,
    projectSlug: convertVcsType(workflow.project_slug)!,
    systemActor: workflow.canceled_by
      ? systemActors[workflow.canceled_by]
      : undefined,
    tag: workflow.tag,
    rerunFailedTestsMetrics: workflowRerunFailedTestsMetricsResponseToObj(
      workflow.created_at,
      workflow.rerun_metrics,
    ),
  };
};

const workflowRerunFailedTestsMetricsResponseToObj = (
  createdAt: string,
  metrics: APIWorkflowRerunFailedTestsMetricsResponse | undefined,
): WorkflowRerunFailedTestsMetrics | undefined => {
  if (!metrics || !metrics.was_rerun_failed_tests) {
    return undefined;
  }
  // Metrics before this date were assigned to the wrong workflow id
  if (createdAt < '2023-08-21T00:00:00Z') {
    return undefined;
  }
  const rerunMetrics: WorkflowRerunFailedTestsMetrics = {
    wasRerunFailedTests: true,
    totalTestCount: metrics.total_test_count,
    failedTestCount: metrics.failed_test_count,
    jobs: [],
    prevTestsDuration: metrics.total_prev_jobs_duration,
  };
  if (metrics.rerun_jobs.length > 0) {
    rerunMetrics.jobs = metrics?.rerun_jobs.map(
      (
        job: APIJobRerunFailedTestsMetricsResponse,
      ): JobRerunFailedTestsMetrics => {
        return {
          id: job.job_id,
          prevDuration: job.total_prev_job_duration,
          totalTestCount: job.total_test_count,
          failedTestCount: job.failed_test_count,
        };
      },
    );
  }
  return rerunMetrics;
};

export default mapToWorkflow;
