import { client } from '@circleci/web-ui-data';
import { BetaProgramSettings } from '~/user-settings/graphql/shim/types';

export const updateAPIBetaProgram = async (
  fetch: any,
  apiKey: string,
  inBetaProgram: boolean,
): Promise<BetaProgramSettings> => {
  const pathname = '/api/v1/user/save-preferences';
  const body = { in_beta_program: inBetaProgram };
  const method = 'PUT';
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    body,
    method,
  });

  return await response.json();
};
