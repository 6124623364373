import { APIServiceOrganization } from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import { Organization, QueryorgArgs } from '~/plans-and-payments/graphql/types';

export default function (
  props: QueryorgArgs,
  organization: APIServiceOrganization,
): Organization {
  return {
    vcsType: props.vcsType,
    name: props.orgName,
    id: organization.id,
  };
}
