import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import MeContext from '~/contexts/MeContext';

import { VCSType } from '~/graphql/shim/types';
import { RebrandNavBar } from '@circleci/web-ui-nav';
import { useOrganization } from '@circleci/api-hooks/private';
import { useMeCollaborations } from '@circleci/api-hooks/v2';
import useOrgHomepageFeature from '~/hooks/misc/useOrgHomepageFeature';

export const NavBar = () => {
  const { query } = useRouter();

  const { me } = React.useContext(MeContext);

  const { data: collaborations, isFetching: collaborationsLoading } =
    useMeCollaborations();

  const collaborationsWithCorrectTypes = useMemo(() => {
    if (!collaborations) {
      return [];
    }

    return collaborations.map((collaboration) => ({
      ...collaboration,
      id: collaboration.id || null,
      vcsType: collaboration.vcsType || '',
      name: collaboration.name || '',
      avatarUrl: collaboration.avatarUrl || null,
    }));
  }, [collaborations]);

  const { data: org } = useOrganization({
    vcsType: query.vcsType as VCSType,
    orgName: query.username as string,
  });
  const humanReadableOrgName = org?.name || (query.username as string);
  const orgSlug = org?.slug || `${query.vcsType}/${query.username}`;

  const { isFeatureEnabled: isOrgHomepageFeatureEnabled } =
    useOrgHomepageFeature();
  const activeLinkName = isOrgHomepageFeatureEnabled
    ? 'Pipelines'
    : 'Dashboard';

  return (
    <RebrandNavBar
      me={me}
      collaborations={collaborationsWithCorrectTypes}
      collaborationsLoading={collaborationsLoading}
      selectedOrg={{
        id: org?.id,
        slug: orgSlug,
        orgName: query.username as string,
        humanReadableOrgName,
        vcsType: query.vcsType as VCSType,
      }}
      activeLinkName={activeLinkName}
      orgLinksPrefix={'pipelines'}
    />
  );
};
