import { client } from '@circleci/web-ui-data';

import { OrganizationInput } from '~/plans-and-payments/graphql/types';

type Fetch = typeof fetch;

export default async ({
  fetch,
  apiKey,
  fromOrganizationVcsType,
  fromOrganizationName,
  toOrganization,
}: {
  fetch: Fetch;
  apiKey?: string;
  fromOrganizationVcsType: string;
  fromOrganizationName: string;
  toOrganization: OrganizationInput;
}) => {
  const pathname = `/api/v1.1/organization/${fromOrganizationVcsType}/${fromOrganizationName}/transfer-plan`;
  return await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'PUT',
    body: {
      'org-name': toOrganization.name,
      'vcs-type': toOrganization.vcsType,
    },
  });
};
