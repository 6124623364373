import {
  APIPipeline,
  Pipeline,
  APIV2Workflow,
  Pipelines,
} from '~/graphql/shim/types';
import pipelineMessages from '~/graphql/shim/mappers/pipelineMessages';
import mapWorkflow from '~/graphql/shim/mappers/workflow';
import convertVcsType from '~/utils/vcs/convertVcsType';
import { getVCSData } from '~/utils/vcs/vcs';
import { VCSData } from '~/types/types';
import { getActorData } from '~/utils/decoupling';
import { ProjectSlug } from '@circleci/web-ui-data/build/types/types';

interface APIPipelineWithWorkflows extends Omit<APIPipeline, 'workflows'> {
  workflows: APIV2Workflow[];
}

const pipeline = ({
  id,
  project_slug,
  state,
  created_at,
  number,
  trigger,
  workflows,
  vcs,
  errors,
  trigger_parameters,
  policy_decision,
}: APIPipelineWithWorkflows): Pipeline => {
  const vcsData: VCSData = getVCSData(vcs, trigger_parameters || {});

  const workflowItems = workflows || [];

  const p = {
    id,
    projectSlug: convertVcsType(project_slug) || ('' as ProjectSlug),
    state,
    createdAt: created_at,
    number: `${number}`,
    workflows: workflowItems.map((wf: APIV2Workflow) => mapWorkflow(wf)),
    branch: vcsData.branch,
    tag: vcsData.tag,
    commit: vcsData.commit,
    vcsRevision: vcsData.revision,
    vcsUrl: vcsData.origin_repository_url,
    actor: getActorData(trigger?.actor, trigger_parameters || {}),
    messages: pipelineMessages(errors),
    triggerType: trigger?.type,
    triggerParameters: {
      circleci: {
        actorId: trigger_parameters?.circleci?.actor_id,
        triggerType: trigger_parameters?.circleci?.trigger_type,
      },
      event: {
        title: trigger_parameters?.event?.title,
        description: trigger_parameters?.event?.description,
      },
      githubApp: {
        branch: trigger_parameters?.github_app?.branch,
        commitSha: trigger_parameters?.github_app?.commit_sha,
        commitTitle: trigger_parameters?.github_app?.commit_title,
        ref: trigger_parameters?.github_app?.ref,
        repoFullName: trigger_parameters?.github_app?.repo_full_name,
        repoUrl: trigger_parameters?.github_app?.repo_url,
        userUsername: trigger_parameters?.github_app?.user_username,
      },
    },
    policyDecision: {
      status: policy_decision?.status,
      enabledRules: policy_decision?.enabled_rules,
      softFailures: policy_decision?.soft_failures,
    },
  };
  return p;
};

interface Props {
  items: APIPipelineWithWorkflows[];
  next_page_token: string;
}

export const mapAPIPipelinesToGraphQL = ({
  items,
  next_page_token,
}: Props): Pipelines => {
  // Guard against items not being defined
  const pipelineItems = items || [];
  return {
    items: pipelineItems.map((pipelineItem) => pipeline(pipelineItem)),
    nextPageToken: next_page_token,
  };
};
