import {
  ComponentFeature,
  FeatureSlug,
} from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import { APIMe } from '~/plans-and-payments/graphql/shim/fetchers/me/me';
import { APIOrganization } from '~/plans-and-payments/graphql/shim/fetchers/organizations-rest/organizations-rest';
import { APIServicePlanV2 } from '~/plans-and-payments/graphql/shim/fetchers/planV2/planV2';
import mapPlanTier from '~/plans-and-payments/graphql/shim/mappers/plan/mapPlanTier';
import {
  CancelReason,
  Organization,
  PlanOverview,
  SubscriptionStatus,
  SupportTier,
  UpgradeSuggestions,
} from '~/plans-and-payments/graphql/types';

function mappedUpgradeSuggestions(
  planV2: APIServicePlanV2,
): UpgradeSuggestions {
  const { computeCredits, milliseconds, userSeats } =
    planV2.organization.planV2.upgradeSuggestions;

  return {
    computeCredits,
    milliseconds,
    userSeats,
  };
}

export function mappedAdminOrgs(orgs: APIOrganization[]): Organization[] {
  return orgs
    .filter((o) => {
      return o.admin;
    })
    .map((o) => {
      return {
        name: o.login,
        vcsType: o.vcs_type,
      };
    });
}

function mappedSubscriptionStatus(
  apiStatus?: string,
): SubscriptionStatus | undefined {
  switch (apiStatus) {
    case 'PAST_DUE':
      return SubscriptionStatus.PAST_DUE;
    case 'PENDING':
      return SubscriptionStatus.PENDING;
    case 'CANCELED':
      return SubscriptionStatus.CANCELED;
    case 'ACTIVE':
      return SubscriptionStatus.ACTIVE;
    case 'INCOMPLETE':
      return SubscriptionStatus.INCOMPLETE;
    default:
      return undefined;
  }
}

function mapCancelReason(
  cancelReason: string | null | undefined,
): CancelReason | undefined {
  switch (cancelReason) {
    case 'UNPAID':
      return CancelReason.UNPAID;
    default:
      return undefined;
  }
}

function mapSupportSlug(slug: FeatureSlug): SupportTier | undefined {
  switch (slug) {
    case FeatureSlug.SUPPORT_TIER_STANDARD:
      return SupportTier.FREE;
    case FeatureSlug.SUPPORT_TIER_FREE:
      return SupportTier.FREE;
    case FeatureSlug.SUPPORT_TIER_SILVER:
      return SupportTier.STARTER;
    case FeatureSlug.SUPPORT_TIER_STARTER:
      return SupportTier.STARTER;
    case FeatureSlug.SUPPORT_TIER_CONTRACTED_STANDARD:
      return SupportTier.CONTRACTED_STANDARD;
    case FeatureSlug.SUPPORT_TIER_GOLD:
      return SupportTier.ADVANCED;
    case FeatureSlug.SUPPORT_TIER_ADVANCED:
      return SupportTier.ADVANCED;
    case FeatureSlug.SUPPORT_TIER_PLATINUM:
      return SupportTier.PREMIUM;
    case FeatureSlug.SUPPORT_TIER_PREMIUM:
      return SupportTier.PREMIUM;
    default:
      return undefined;
  }
}

function mappedSupportTier(
  features: ComponentFeature[],
): SupportTier | undefined {
  return features
    .map((feature) => {
      return mapSupportSlug(feature.slug);
    })
    .find((supportTier) => {
      return supportTier !== undefined;
    });
}

function trialFeature(
  features: ComponentFeature[],
): ComponentFeature | undefined {
  return features.find((feature) => {
    return feature.slug === FeatureSlug.TRIAL;
  });
}

function trialFeatureStopDate(
  features: ComponentFeature[],
): string | undefined {
  const trialFeat = trialFeature(features);
  return trialFeat !== undefined ? trialFeat.stopDate : undefined;
}
export default function (
  me: APIMe,
  planV2: APIServicePlanV2,
  orgs: APIOrganization[],
): PlanOverview {
  return {
    currentUser: {
      id: me.analytics_id,
      selectedEmail: me.selected_email,
      adminOrgs: mappedAdminOrgs(orgs),
    },
    plan: {
      tier: mapPlanTier(planV2),
      trialEnd: trialFeature(planV2.organization.planV2.features)
        ? trialFeatureStopDate(planV2.organization.planV2.features)
        : undefined,
      isTrial:
        trialFeature(planV2.organization.planV2.features) !== undefined ||
        planV2.organization.planV2.isTrial,
      subscriptionStatus: mappedSubscriptionStatus(
        planV2.organization.planV2.billingSubscription?.status,
      ),
      billingProvider:
        planV2.organization.planV2.billingSubscription?.billingProvider || '',
      cancelReason: mapCancelReason(
        planV2.organization.planV2.billingSubscription?.cancelReason,
      ),
      supportTier: mappedSupportTier(planV2.organization.planV2.features),
    },
    upgradeSuggestions: mappedUpgradeSuggestions(planV2),
  };
}
