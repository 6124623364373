import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import {
  APIServiceOrganization,
  APIServiceOrganizationData,
} from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import { vcsTypeMap } from '~/plans-and-payments/graphql/vcsTypeMap';

const query = gql`
  query Organization($vcsType: VCSType!, $orgName: String!) {
    organization(vcsType: $vcsType, name: $orgName) {
      id
    }
  }
`;

interface Props {
  vcsType: string;
  orgName: string;
}

const fetchOrganization = async ({
  vcsType,
  orgName,
}: Props): Promise<APIServiceOrganization> => {
  const result = await apiServiceClient.query<APIServiceOrganizationData>({
    query,
    variables: { vcsType: vcsTypeMap[vcsType], orgName },
  });

  const defaults: APIServiceOrganization = {
    id: '',
  };

  return result.data?.organization ? result.data.organization : defaults;
};

export default fetchOrganization;
