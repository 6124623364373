import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import { ComponentFeature } from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import { vcsTypeMap } from '~/plans-and-payments/graphql/vcsTypeMap';

const query = gql`
  query PlanV2($name: String!, $vcsType: VCSType!) {
    organization(name: $name, vcsType: $vcsType) {
      id
      planV2 {
        isTrial
        tier
        billingSubscription {
          status
          cancelReason
          billingProvider
        }
        upgradeSuggestions {
          computeCredits
          milliseconds
          userSeats
        }
        contract {
          termStartEnd {
            end
            start
          }
        }
        features {
          slug
          quantity
          stopDate
        }
      }
    }
  }
`;

export interface FetchPlanV2 {
  orgName: string;
  vcsType: string;
}

export enum APIServicePlanTier {
  CONTAINER = 'CONTAINER',
  CUSTOM_CONTAINER = 'CUSTOM_CONTAINER',
  SCALE = 'SCALE',
  CUSTOM_PERFORMANCE = 'CUSTOM_PERFORMANCE',
  FREE = 'FREE',
  PERFORMANCE = 'PERFORMANCE',
  GITHUB_MARKETPLACE = 'GITHUB_MARKETPLACE',
}

export interface APIServiceContract {
  termStartEnd: {
    end: Date;
    start: Date;
  };
}

export interface APIServicePlanV2 {
  organization: {
    id: string;
    planV2: {
      isTrial: boolean;
      tier: APIServicePlanTier;
      billingSubscription?: {
        status: string;
        cancelReason: string | null;
        billingProvider: string;
      };
      upgradeSuggestions: {
        computeCredits: string;
        milliseconds: string;
        userSeats: number;
      };
      contract?: APIServiceContract;
      features: ComponentFeature[];
    };
  };
}

const FetchPlanV2 = async (props: FetchPlanV2): Promise<APIServicePlanV2> => {
  const res = await apiServiceClient.query<APIServicePlanV2>({
    query,
    variables: {
      name: props.orgName,
      vcsType: vcsTypeMap[props.vcsType],
    },
  });

  const defaults: APIServicePlanV2 = {
    organization: {
      id: '',
      planV2: {
        isTrial: false,
        tier: APIServicePlanTier.FREE,
        upgradeSuggestions: {
          computeCredits: '0',
          milliseconds: '0',
          userSeats: 0,
        },
        features: [],
      },
    },
  };

  return res.data ? res.data : defaults;
};

export default FetchPlanV2;
