import { gql } from '@apollo/client';
import apiServiceClient from '~/graphql/apiServiceClient';

const query = gql`
  query Usage($orgId: String!, $offset: Int) {
    plan(orgId: $orgId) {
      id
      billingPeriods(numPeriods: 1, offset: $offset) {
        period {
          start
          end
        }
        metrics {
          activeUsers {
            totalCount
          }
          projects(filter: { usingDLC: true }) {
            totalCount
          }
          total {
            computeCredits
            credits
            dlcCredits
            seconds
            userCredits
            networkCredits
            storageCredits
            networkEgress {
              totalBytes
            }
            storageV2 {
              totalBytes
            }
            ipRanges
            ipRangesCredits
          }
          byProject {
            nodes {
              aggregate {
                credits
                seconds
                dlcCredits
                computeCredits
                networkV2ByProject
                storageV2ByProject
              }
              project {
                name
                username
              }
              aggregateByResourceClass {
                executor
                metric {
                  computeCredits
                  credits
                  seconds
                }
                resourceClass
              }
              ipRangesMetric {
                type
                bytes
                credits
              }
            }
            totalByResourceClass {
              executor
              metric {
                computeCredits
                credits
                seconds
              }
              resourceClass
            }
          }
          byDataObject {
            nodes {
              name
              metric {
                storageV2
                networkV2
              }
            }
          }
          thresholds {
            network {
              free
              performance
              custom
              scale
            }
            storage {
              free
              performance
              custom
              scale
            }
          }
          leases {
            count
            leaseCreditsCharged
            overageCreditsCharged
            overageMinutes
          }
        }
      }
    }
  }
`;

export interface FetchUsage {
  orgId: string;
  offset: number;
}

/* query to get the usage data for given numPeriods and offset
by default this only returns the data for the first period.
This ensures that the data loaded does not timeout the page
because of small data-size. */
export default async (props: FetchUsage): Promise<any> => {
  const res = await apiServiceClient.query<any>({
    query,
    variables: props,
  });
  return res.data;
};
