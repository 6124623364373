import { Forbidden } from '@circleci/web-ui-data/build/client/errors';

import exceptionLogger from '~/plans-and-payments/frontplane/exceptionLogger';
import {
  TransferError,
  TransferPlanResponse,
} from '~/plans-and-payments/graphql/types';

const paidPlanError = (error: Error) =>
  error instanceof Forbidden && error.message.includes('is on a paid plan');

const logUnknownError = (error: Error) => {
  exceptionLogger.warn('API error when transferring a plan', {
    error: error.message,
  });
};

export default (error: Error): TransferPlanResponse => {
  if (paidPlanError(error)) {
    return { error: TransferError.PAID_PLAN };
  }
  logUnknownError(error);
  return { error: TransferError.UNKNOWN };
};
