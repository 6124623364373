import exceptionLogger from '~/plans-and-payments/frontplane/exceptionLogger';

const logUnknownError = (error: Error) => {
  exceptionLogger.warn('Failing to do PUT request', {
    error: error.message,
  });
};

export default (error: Error): any => {
  logUnknownError(error);
  return { enabled: false };
};
