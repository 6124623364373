const userSettingstypeDefs = `
type BetaProgramSettings {
  inBetaProgram: Boolean!
}

type IntegrationSettingsItem {
  isAuthorized: Boolean!
  login: String
}

type IntegrationSettings {
  github: IntegrationSettingsItem!
  bitbucket: IntegrationSettingsItem!
}

type ProjectNotificationSettings {
  emails: String!
  reponame: String
  vcsUrl: String!
}

type NonMemberOrganizationNotificationSettings {
  name: String!
  email: String
  vcsType: String!
  projects: [ProjectNotificationSettings!]!
}

type MemberOrganizationNotificationSettings {
  name: String!
  email: String
  vcsType: String!
  avatarUrl: String
  projects: [ProjectNotificationSettings!]!
}

type NotificationSettings {
  allEmails: [String!]!
  selectedEmail: String!
  basicEmailPrefs: String!
  memberOrganizations: [MemberOrganizationNotificationSettings!]!
  nonMemberOrganizations: [NonMemberOrganizationNotificationSettings!]!
}

type OrganizationPlanSettings {
  admin: Boolean!
  avatarUrl: String!
  login: String!
  name: String!
  vcsType: String!
  shortVcsType: String!
}

type PrivacySettings {
  privacyOptout: Boolean!
}

type PrivacySettingsResponse {
  privacy_optout: Boolean!
}

type TokenSettings {
  id: String!
  label: String
  time: String!
  token: String
}

extend type Query {
  betaProgramSettings: BetaProgramSettings
  integrationSettings: IntegrationSettings
  notificationSettings: NotificationSettings
  organizationPlanSettings: [OrganizationPlanSettings]
  privacySettings: PrivacySettings
  personalTokenSettings: [TokenSettings]
  userSettingsOrganizations: [Organization!]!
}

type TokenSettingDeleteResponse {
  message: String!
}

type RefreshUserPermissionsResponseErrorType {
  type: String
}

type RefreshUserPermissionsResponse {
  errors: RefreshUserPermissionsResponseErrorType
}

type DisconnectVCSResponse {
  message: String!
}

extend type Mutation {
  updatePrivacyOptout(privacyOptout: Boolean!): PrivacySettingsResponse
  updateBetaProgram(inBetaProgram: Boolean!): BetaProgramSettings
  updateDefaults(selectedEmail: String, basicEmailPrefs: String): NotificationSettings
  updateOrgPrefs(email: String, vcsType: String, login: String): NotificationSettings
  updateProjPrefs(emails: String, vcsUrl: String): NotificationSettings
  refreshUserPermissions: RefreshUserPermissionsResponse
  createToken(label: String): TokenSettings
  removeToken(tokenId: String): TokenSettingDeleteResponse
  changeEmail(password: String, newEmail: String): String
  changePassword(oldPassword: String, newPassword: String): String
  disconnectVCS(vcsType: String): DisconnectVCSResponse
}
`;

export default userSettingstypeDefs;
