import { client } from '@circleci/web-ui-data';
import { APIProject } from '~/user-settings/graphql/shim/types';

export default async (fetch: any, apiKey: string): Promise<APIProject[]> => {
  const query = { shallow: 'true', branch_limit: '100' };
  const pathname = '/api/v1/projects';
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });

  return await response.json();
};
