import {
  APIServiceActiveUsersData,
  APIServiceUser,
} from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import { ActiveUser, UsersPage } from '~/plans-and-payments/graphql/types';

export const UNKNOWN_USER = 'Unknown User';
export const UNREGISTERED_USER = 'Unregistered User';

function mapUsers(props: APIServiceActiveUsersData): ActiveUser[] {
  return props.plan.metrics.activeUsers.edges.map((user: APIServiceUser) => ({
    user: {
      id: user.node.id,
      name: user.node.name ? user.node.name : UNKNOWN_USER,
      avatarUrl: user.node.avatarURL,
    },
    lastActiveDate: user.lastActiveDate,
  }));
}

export default (props: APIServiceActiveUsersData): UsersPage => ({
  pageInfo: props.plan.metrics.activeUsers.pageInfo,
  totalCount: props.plan.metrics.activeUsers.totalCount,
  activeUsers: mapUsers(props),
});
