import { gql, useQuery } from '@apollo/client';
import { containsOnlyNetworkErrors } from '@circleci/web-ui-data/build/client/errors';

const query = gql`
  query MyAPIServicePlan($orgId: String!) {
    plan(orgId: $orgId) {
      id
    }
  }
`;

interface Props {
  orgId?: string;
}

interface Plan {
  id: string;
}
interface Result {
  error?: Error;
  loading: boolean;
  plan?: Plan;
}

const useAPIServicePlan = (props: Props): Result => {
  const { loading, error, data } = useQuery<any>(query, {
    fetchPolicy: 'cache-and-network',
    variables: props,
  });

  return {
    loading,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    error: containsOnlyNetworkErrors(error!) ? undefined : error,
    plan: data?.plan,
  };
};

export default useAPIServicePlan;
