import * as React from 'react';
import { useKBar, Action } from 'kbar';
import { AddProjectsIcon } from '@circleci/compass';
import useCollaborations from '~/hooks/misc/useCollaborations';
import router from 'next/router';

export const getBasePath = (origin: string, page: string) => {
  if (page === 'pipelines') {
    return `${origin}/pipelines`;
  }

  return `${origin}/organization`;
};

export const useCollaborationActions = () => {
  const { collaborations } = useCollaborations();
  const { query } = useKBar();
  const page = window.location.pathname.split('/')[1];
  const origin = window.location.origin;
  const newBasePath = getBasePath(origin, page);
  const actions = collaborations?.map((collaboration) => {
    const encodedSlug = encodeURIComponent(collaboration?.slug || '');
    const items: Action[] = [
      {
        id: `${collaboration.vcsType}/${collaboration.name}`,
        name: collaboration.name,
        keywords: collaboration.slug,
        section: 'Organizations',
        perform: () => router.push(`${newBasePath}/${collaboration.slug}`),
      },
    ];

    if (collaboration.vcsType === 'circleci') {
      items.push({
        id: `${collaboration.vcsType}/${collaboration.name}-newproject`,
        name: 'Create Project',
        keywords: 'create project',
        icon: <AddProjectsIcon />,
        section: 'Organizations',
        parent: `${collaboration.vcsType}/${collaboration.name}`,
        perform: () => router.push(`/projects/create-project/${encodedSlug}`),
      });
    }
    return items;
  });

  query.registerActions(actions?.flat() || []);
};
