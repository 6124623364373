import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  vcsType: string;
  fetch: typeof window.fetch;
}

const disconnectVCS = async ({ apiKey, fetch, vcsType }: Props) => {
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname: '/api/private/user/unlink-vcs/' + vcsType.toLowerCase(),
    method: 'POST',
    callerName: 'disconnectVCS',
  });

  return response.json();
};

export default disconnectVCS;
