import { gql, useQuery } from '@apollo/client';

import { PlanTier, PlanUsage } from '~/plans-and-payments/graphql/types';
import { VCSType } from '~/plans-and-payments/types';

const query = gql`
  query PlanUsage($orgName: String!, $vcsType: String!) {
    planUsage(orgName: $orgName, vcsType: $vcsType) {
      plan {
        tier
        features {
          slug
          quantity
        }
      }
    }
  }
`;

interface Data {
  planUsage: PlanUsage;
}

const usePlanUsage = (props: { vcsType: VCSType; orgName: string }) => {
  const { loading, data, error } = useQuery<Data>(query, {
    variables: {
      orgName: props.orgName,
      vcsType: props.vcsType,
    },
  });

  const planDefaults: PlanUsage = {
    plan: {
      tier: PlanTier.PERFORMANCE,
      features: [],
    },
  };

  return {
    planUsage: data ? data.planUsage : planDefaults,
    loading,
    error,
  };
};

export default usePlanUsage;
