import {
  APIPersonalToken,
  TokenSetting,
} from '~/user-settings/graphql/shim/types';

export default ({
  id,
  label,
  time,
  token,
}: APIPersonalToken): TokenSetting => ({
  id,
  label,
  time,
  token,
});
