import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import { DateTimeRange } from '~/plans-and-payments/graphql/types';
import { CustomError } from '~/plans-and-payments/utils/customError';

const query = gql`
  query PlanUsageMetrics($orgId: String!, $dateRange: DateTimeRangeInput!) {
    plan(orgId: $orgId) {
      id
      billingPeriods(numPeriods: 1) {
        metrics {
          activeUsers {
            totalCount
          }
          total {
            credits
          }
        }
      }
      metrics(dateRange: $dateRange) {
        total {
          credits
        }
        activeUsers {
          totalCount
        }
        OSSLinuxCreditsUsed: byCreditType(creditType: LINUX_OSS) {
          credits
        }
        privateLinuxCreditsUsed: byCreditType(creditType: LINUX_PRIVATE) {
          credits
        }
        OSSOSXCreditsUsed: byCreditType(creditType: OSX_OSS) {
          credits
        }
        privateOSXCreditsUsed: byCreditType(creditType: OSX_PRIVATE) {
          credits
        }
      }
      creditsAvailable
      features {
        slug
        quantity
      }
    }
  }
`;

export interface FetchComponentUsage {
  orgId: string;
  dateRange: DateTimeRange;
}

export default async (props: FetchComponentUsage): Promise<any> => {
  const res = await apiServiceClient.query<any>({
    query,
    variables: props,
  });

  if (res && res.data) {
    return res.data;
  } else {
    throw new CustomError(
      `Error fetching PlanUsageMetrics for org ${props.orgId}`,
      { props, res },
    );
  }
};
