import * as R from 'ramda';
import { client } from '@circleci/web-ui-data';
import { APISettings } from '~/user-settings/graphql/shim/types';

export default async (
  fetch: any,
  apiKey: string,
  payload: Record<string, any>,
): Promise<APISettings> => {
  const compactPayload = R.reject(R.equals(undefined), payload);
  const pathname = '/api/v1/user/save-preferences';
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    body: compactPayload,
    method: 'PUT',
  });

  return await response.json();
};
