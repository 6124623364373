import Router from 'next/router';
import * as React from 'react';

import assetBaseURLPrefix from '~/utils/assetBaseURLPrefix';
import { ErrorPage } from '@circleci/compass';
import { trackPage } from '@circleci/analytics';
import getIsEnterprise from '~/utils/getIsEnterprise';

type Props = {
  onGoBack?: () => void;
};

const NotFound: React.FC<Props> = ({ onGoBack = () => Router.back() }) => {
  React.useEffect(() => {
    trackPage('404 Error', {});
  }, []);
  return (
    <ErrorPage
      onActionClick={onGoBack}
      isEnterprise={getIsEnterprise()}
      fgImageUrl={`${assetBaseURLPrefix}/pipelines/background-images/404-door.png`}
      bgImageUrl={`${assetBaseURLPrefix}/pipelines/background-images/stars.jpg`}
    />
  );
};

export default NotFound;
