import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceBillingPortalData } from '~/plans-and-payments/graphql/shim/apiServiceTypes';

const query = gql`
  query PlanOverview($orgId: String!) {
    plan(orgId: $orgId) {
      id
      externalManagementPortalLink
    }
  }
`;

export interface FetchBillingPortal {
  orgId: string;
}

export default async (
  props: FetchBillingPortal,
): Promise<APIServiceBillingPortalData> => {
  const res = await apiServiceClient.query<APIServiceBillingPortalData>({
    query,
    variables: props,
  });

  const defaults: APIServiceBillingPortalData = {
    plan: {
      externalManagementPortalLink: '',
    },
  };

  return res.data ? res.data : defaults;
};
