import * as React from 'react';

import { CircleLogoIcon } from '@circleci/compass';

import LoadingIcon from '~/plans-and-payments/components/shared/LoadingIcon';
import styled from '~/styled';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-grow: 1;
`;

const Text = styled.span`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.n900};
  font-size: ${({ theme }) => theme.legacy.fontSizes[5]}px;
  font-weight: ${({ theme }) => theme.legacy.fontWeights.bold};
`;

const Loading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > :not(:last-child) {
    margin-right: ${({ theme }) => theme.legacy.space[2]}px;
  }
`;

const IconWrap = styled.div`
  border-left: 1px solid #d4d4d4;

  > span {
    margin-left: ${({ theme }) => theme.legacy.space[1]}px;
  }
`;

const LogoWrap = styled.div`
  width: ${({ theme }) => theme.legacy.space[6]}px;
  height: ${({ theme }) => theme.legacy.space[6]}px;
  color: ${({ theme }) => theme.colors.n900};
`;

export default () => (
  <Wrap>
    <Loading>
      <LogoWrap>
        <CircleLogoIcon />
      </LogoWrap>
      <Text>Loading</Text>
      <IconWrap>
        <LoadingIcon />
      </IconWrap>
    </Loading>
  </Wrap>
);
