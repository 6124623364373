import { APIServicePlan } from '~/plans-and-payments/graphql/types';

const mapAPIServicePlan = (plan: any): APIServicePlan => ({
  tier: plan.tier,
  highWatermark: plan['high-water-mark'],
  isInactive: plan['is-inactive'],
  id: plan.id,
  lowWatermark: plan['low-water-mark'],
  createdAt: plan['created-at'],
});

export default mapAPIServicePlan;
