import * as R from 'ramda';
import fetchAPISettings from '~/user-settings/graphql/shim/fetchers/settings';
import fetchAPIOrganizations from '~/user-settings/graphql/shim/fetchers/organizations';
import fetchAPITokens from '~/user-settings/graphql/shim/fetchers/tokens';
import fetchAPIProjects from '~/user-settings/graphql/shim/fetchers/projects';
import fetchAPICollaborations from '~/user-settings/graphql/shim/fetchers/collaborations';
import changeNotifications from '~/user-settings/graphql/shim/mutators/notifications';
import mapAPIOrganizationsToGraphQL from '~/user-settings/graphql/shim/mappers/organizationPlanSettings';
import mapAPITokenSettingsToGraphQL from '~/user-settings/graphql/shim/mappers/tokenSettings';
import mapAPIBetaProgramSettingsToGraphQL from '~/user-settings/graphql/shim/mappers/betaProgramSettings';
import mapAPIIntegrationSettingsToGraphQL from '~/user-settings/graphql/shim/mappers/integrationSettings';
import mapAPINotificationSettingsToGraphQL from '~/user-settings/graphql/shim/mappers/notificationSettings';
import mapAPIPrivacySettingsToGraphQL from '~/user-settings/graphql/shim/mappers/privacySettings';
import { updateAPIPrivacyOptout } from '~/user-settings/graphql/shim/mutators/privacy';
import { updateAPIBetaProgram } from '~/user-settings/graphql/shim/mutators/betaProgram';
import refreshUserPermissions from '~/user-settings/graphql/shim/mutators/refreshPermissions';
import {
  createAPIToken,
  removeAPIToken,
} from '~/user-settings/graphql/shim/mutators/tokens';
import disconnectVCS from '~/user-settings/graphql/shim/mutators/disconnectVCS';

interface Deps {
  apiKey?: string;
  fetch: typeof window.fetch;
}

const userSettingsresolvers = {
  Query: {
    organizationPlanSettings: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const apiOrgs = await fetchAPIOrganizations(fetch as any, apiKey as any);
      return R.map(mapAPIOrganizationsToGraphQL, apiOrgs);
    },
    userSettingsOrganizations: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const apiOrgs = await fetchAPIOrganizations(fetch as any, apiKey as any);
      return R.map(mapAPIOrganizationsToGraphQL, apiOrgs);
    },
    privacySettings: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const apiSetings = await fetchAPISettings(fetch as any, apiKey as any);
      return mapAPIPrivacySettingsToGraphQL(apiSetings);
    },
    betaProgramSettings: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const apiSetings = await fetchAPISettings(fetch as any, apiKey as any);
      return mapAPIBetaProgramSettingsToGraphQL(apiSetings);
    },
    integrationSettings: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const apiSetings = await fetchAPISettings(fetch as any, apiKey as any);
      return mapAPIIntegrationSettingsToGraphQL(apiSetings);
    },
    notificationSettings: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const apiSettings = await fetchAPISettings(fetch as any, apiKey as any);
      const apiCollaboration = await fetchAPICollaborations(fetch, apiKey);
      const apiProjects = await fetchAPIProjects(fetch as any, apiKey as any);

      return mapAPINotificationSettingsToGraphQL(
        apiSettings,
        apiProjects,
        apiCollaboration,
      );
    },
    personalTokenSettings: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const apiTokens = await fetchAPITokens(fetch as any, apiKey as any);
      return R.map(mapAPITokenSettingsToGraphQL, apiTokens);
    },
  },
  Mutation: {
    updatePrivacyOptout: async (
      _root: any,
      { privacyOptout }: any,
      { fetch, apiKey }: Deps,
    ) => {
      const response = await updateAPIPrivacyOptout(
        fetch as any,
        apiKey as any,
        privacyOptout,
      );
      return response;
    },
    updateBetaProgram: async (
      _root: any,
      { inBetaProgram }: any,
      { fetch, apiKey }: Deps,
    ) => {
      const response = await updateAPIBetaProgram(
        fetch as any,
        apiKey as any,
        inBetaProgram as any,
      );
      return response;
    },
    refreshUserPermissions: async (_root: any) => {
      return refreshUserPermissions();
    },
    updateDefaults: async (
      _root: any,
      { selectedEmail, basicEmailPrefs }: any,
      { fetch, apiKey }: Deps,
    ) => {
      const response = await changeNotifications(fetch, apiKey as string, {
        selected_email: selectedEmail,
        basic_email_prefs: basicEmailPrefs,
      });
      return response;
    },
    updateOrgPrefs: async (
      _root: any,
      { email, vcsType, login }: any,
      { fetch, apiKey }: Deps,
    ) => {
      const payload = {
        organization_prefs: {
          [vcsType]: {
            [login]: {
              email: email == 'Default' ? null : email,
            },
          },
        },
      };
      const response = await changeNotifications(
        fetch as any,
        apiKey as any,
        payload as any,
      );
      return response;
    },
    updateProjPrefs: async (
      _root: any,
      { emails, vcsUrl }: any,
      { fetch, apiKey }: Deps,
    ) => {
      const payload = {
        projects: {
          [vcsUrl]: {
            emails: emails,
          },
        },
      };
      const response = await changeNotifications(
        fetch as any,
        apiKey as any,
        payload as any,
      );
      return response;
    },
    createToken: async (
      _root: any,
      { label }: any,
      { fetch, apiKey }: Deps,
    ) => {
      const response = await createAPIToken(fetch, apiKey as any, label);
      return response;
    },
    removeToken: async (
      _root: any,
      { tokenId }: any,
      { fetch, apiKey }: Deps,
    ) => {
      const response = await removeAPIToken(fetch, apiKey as any, tokenId);
      return response;
    },
    disconnectVCS: async (
      _root: any,
      { vcsType }: any,
      { fetch, apiKey }: Deps,
    ) => {
      await disconnectVCS({ apiKey, fetch, vcsType } as any);
    },
  },
};

export default userSettingsresolvers;
