import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceActiveUsersData } from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import { DateTimeRange } from '~/plans-and-payments/graphql/types';

const query = gql`
  query ActiveUsers(
    $dateRange: DateTimeRangeInput!
    $orgId: String!
    $first: Int!
    $offset: Int!
  ) {
    plan(orgId: $orgId) {
      id
      metrics(dateRange: $dateRange) {
        activeUsers(first: $first, offset: $offset) {
          totalCount
          pageInfo {
            hasNextPage
            endOfPageIndex
            startOfPageIndex
            hasPreviousPage
          }
          edges {
            lastActiveDate
            node {
              avatarURL
              id
              name
            }
          }
        }
      }
    }
  }
`;

export interface FetchActiveUsers {
  orgId: string;
  dateRange: DateTimeRange;
  first: number;
  offset: number;
}

export default async (
  props: FetchActiveUsers,
): Promise<APIServiceActiveUsersData> => {
  const res = await apiServiceClient.query<APIServiceActiveUsersData>({
    query,
    variables: props,
  });

  const defaults: APIServiceActiveUsersData = {
    plan: {
      metrics: {
        activeUsers: {
          totalCount: 0,
          pageInfo: {
            hasNextPage: false,
            endOfPageIndex: 0,
            startOfPageIndex: 0,
            hasPreviousPage: false,
          },
          edges: [],
        },
      },
    },
  };

  return res.data ? res.data : defaults;
};
