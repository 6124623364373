import { APIServicePlanV2 } from '~/plans-and-payments/graphql/shim/fetchers/planV2/planV2';
import mapPlanTier from '~/plans-and-payments/graphql/shim/mappers/plan/mapPlanTier';
import { PlanUsage } from '~/plans-and-payments/graphql/types';

export default function (planV2: APIServicePlanV2): PlanUsage {
  return {
    plan: {
      tier: mapPlanTier(planV2),
      features: planV2.organization.planV2.features,
    },
  };
}
