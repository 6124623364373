import { client } from '@circleci/web-ui-data';
import { PrivacySettings } from '~/user-settings/graphql/shim/types';

export const updateAPIPrivacyOptout = async (
  fetch: any,
  apiKey: string,
  privacyOptout: boolean,
): Promise<PrivacySettings> => {
  const pathname = '/api/v1/user/save-preferences';
  const body = { privacy_optout: privacyOptout };
  const method = 'PUT';
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    body,
    method,
  });

  return await response.json();
};
