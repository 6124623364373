import { onError } from '@apollo/client/link/error';

import {
  isNetworkError,
  graphQLErrorIsNetworkError,
  isForbidden,
  isUnauthorized,
  isNotFoundError,
  isUnprocessableRequest,
  isBadRequest,
} from '@circleci/web-ui-data/build/client/errors';
import exceptionLogger from '~/user-settings/utils/exceptionLogger';

const link = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    exceptionLogger.warn('[Network error]', networkError);
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (graphQLErrorIsNetworkError(error) || isNetworkError(error)) {
        return;
      }

      if (
        error.message === 'You must log in first.' ||
        isBadRequest(error) ||
        isBadRequest(error.originalError as Error) ||
        isUnauthorized(error) ||
        isUnauthorized(error.originalError as Error) ||
        isForbidden(error) ||
        isForbidden(error.originalError as Error) ||
        isNotFoundError(error) ||
        isNotFoundError(error.originalError as Error) ||
        isUnprocessableRequest(error) ||
        isUnprocessableRequest(error.originalError as Error)
      ) {
        return;
      }
      const { message, locations, path } = error;
      exceptionLogger.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        error,
      );
    });
  }
});

export default link;
