import global from './global';

// assetPrefix can be set dynamically, so comes from publicRuntimeConfig
let assetPrefix = global.circleci?.config?.assetPrefix;
if (assetPrefix && !assetPrefix.endsWith('/')) {
  assetPrefix = assetPrefix + '/';
}

// buildId is set at build time, so comes from the build environment, exposed through the `env` property
const buildId = process.env.BUILD_ID || '';

const result =
  assetPrefix && buildId ? `${assetPrefix}_next/static/${buildId}` : '';

export default result;
