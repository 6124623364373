import * as url from 'url';

import * as React from 'react';

import { Page as AnalyticsPage, useTrackAction } from '@circleci/analytics';
import { TrackAction } from '@circleci/analytics/build/src/hooks/useTrackAction';
import { Button, Flex, LargeHeading, Paragraph } from '@circleci/compass';
import { css } from '@emotion/core';

import { mediaDesktop } from '~/plans-and-payments/components/shared/viewPorts';
import styled from '~/styled';
import { VCSType } from '~/plans-and-payments/types';
import {
  AnalyticsPageProps,
  getOrgSlug,
  staticAnalyticsPageProps,
} from '~/plans-and-payments/utils/analytics';

import { AnalyticsEventPropertiesContext } from '../AnalyticsEventPropertiesProvider';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${mediaDesktop} {
    flex-direction: row;
  }
`;

const ErrorWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.space.space16};
    max-width: 670px;
    margin: 0 auto;
  `;
});

interface ErrorMessageProps {
  title?: string;
  paragraph?: string;
  ctaHref?: string | null;
  orgName?: string;
  vcsType?: VCSType;
}

export const ErrorMessage = ({
  title = 'Something Unexpected Happened',
  paragraph = "This is certainly annoying, our apologies. Maybe a refresh would help. If that doesn't work, you may not have permissions to view this page or you may have the incorrect URL.",
  ctaHref,
}: ErrorMessageProps): React.ReactElement => {
  const analyticsEventProperties = React.useContext(
    AnalyticsEventPropertiesContext,
  );

  const trackAction: TrackAction = useTrackAction({
    object: 'pageError',
    eventProperties: {
      ...analyticsEventProperties,
    },
  });

  return (
    <Wrap data-cy="page-error">
      <ErrorWrapper>
        <LargeHeading
          as="h1"
          fontWeight="bold"
          color="n700"
          paddingBottom="space8"
        >
          {title}
        </LargeHeading>
        <Paragraph marginTop="space16" marginBottom="space16">
          {paragraph}
        </Paragraph>
        {ctaHref && (
          <Flex>
            <Button
              href={ctaHref}
              onClick={(): void => {
                trackAction('clicked-error-page-cta-button');
              }}
              variation="primary"
            >
              Login
            </Button>
          </Flex>
        )}
      </ErrorWrapper>
    </Wrap>
  );
};

interface PageErrorProps {
  orgName: string;
  vcsType: VCSType;
  isUnauthorized?: boolean | undefined;
}

const PageError = ({
  orgName,
  vcsType,
  isUnauthorized,
}: PageErrorProps): JSX.Element => {
  const redirectUrl = url.format({
    pathname: '/api/login',
    query: {
      'return-to': window.location.href,
    },
  });
  const analyticsPageProps: AnalyticsPageProps = {
    ...staticAnalyticsPageProps,
    pageName: 'planError',
    orgSlug: getOrgSlug(vcsType, orgName),
  };

  return (
    <AnalyticsPage {...analyticsPageProps}>
      {isUnauthorized ? (
        <ErrorMessage
          title="Missing credentials"
          paragraph="Apologies, you may need to login again to see this page. Click the button below to login, then we’ll bring you back to this page."
          ctaHref={redirectUrl}
          orgName={orgName}
          vcsType={vcsType}
        />
      ) : (
        <ErrorMessage />
      )}
    </AnalyticsPage>
  );
};

export default PageError;
