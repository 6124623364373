import { EventProperties } from '@circleci/analytics/build/src/types/EventProperties';

// -------------------------------------------------
// Event Properties
// -------------------------------------------------
const CORE_EVENT_PROPERTIES: CoreEventProperties = {
  sender: 'webapp',
  teamName: 'monetization', // from the Page component
  userId: '', // from the Page component if there is an user
  organizationId: '', // from the Page component if there is an organization
  action: '', // not overridable. set by the first parameter when calling trackAction
  anonymousId: null,
  circleciAnonymousId: null, // not overridable. generated by analytics package
  isGrowthAnalytics: false, // set if using event proxy for circumventing ad blockers (generally `false` unless Product/DSA have explicitly stated that we should be using this)
};

const MONETIZATION_EVENT_PROPERTIES: MonetizationEventProperties = {
  planTier: '',
  supportTier: '',
  isPrepaid: null,
  isAdmin: null,
};

const PAGE_EVENT_PROPERTIES: PageEventProperties = {
  pageType: 'inner' as PageType,
  location: '',
  pageName: '', // absolutely MUST be a camelCase value for Page analytics to work properly
};

const COMPONENT_EVENT_PROPERTIES: ComponentEventProperties = {
  sublocation: '',
};

const CLICK_EVENT_PROPERTIES: ClickEventProperties = {
  linkUrl: '',
};

const EVENT_PROPERTIES: EventProperties = {
  ...CORE_EVENT_PROPERTIES,
  ...MONETIZATION_EVENT_PROPERTIES,
  ...PAGE_EVENT_PROPERTIES,
  ...COMPONENT_EVENT_PROPERTIES,
  ...CLICK_EVENT_PROPERTIES,
};

export const staticAnalyticsPageProps: AnalyticsPageProps = {
  sender: 'webapp',
  teamName: 'monetization',
  pageType: 'inner',
  pageName: '', // absolutely MUST be a camelCase value for Page analytics to work properly
  orgSlug: '', // fill in later
};

// -------------------------------------------------
// Event Property Types and Interfaces
// -------------------------------------------------
type PageType = 'inner' | 'outer';

interface CoreEventProperties {
  sender: string;
  teamName: string;
  userId: string;
  organizationId: string;
  action: string;
  anonymousId: string | null;
  circleciAnonymousId: string | null;
  isGrowthAnalytics: boolean;
}
interface MonetizationEventProperties {
  planTier: string;
  supportTier: string;
  isPrepaid: boolean | null;
  isAdmin: boolean | null;
  source?: string;
  event?: string;
}
interface PageEventProperties {
  pageType: PageType; // inner
  pageName: string; // absolutely MUST be a camelCase value for Page analytics to work properly
  location: string; // legacy property - same as pageName
}
interface ComponentEventProperties {
  sublocation: string; // sub-page location (e.g. card component, form, etc.)
  treatment?: string; // any special product/marketing/design UI treatment
  isExpanded?: boolean; // whether or not a card component is expanded by default
}

export interface AnalyticsPageProps {
  sender: string;
  pageType: PageType;
  pageName: string;
  teamName: string;
  orgSlug: string;
}

interface ClickEventProperties {
  linkUrl: string;
}

// -------------------------------------------------
// Tracked Actions
// -------------------------------------------------
export enum TrackedAction {
  BUTTON_CLICKED = 'button-clicked',
  BUTTON_HOVERED = 'button-hovered',
  LINK_CLICKED = 'link-clicked',
  CONTENT_VIEWED = 'content-viewed',
}

// -------------------------------------------------
// Utility Functions
// -------------------------------------------------
export const getDefaultAnalyticsEventProperties = (
  defaultEventProperties: Partial<EventProperties> = {},
): EventProperties => {
  return {
    ...EVENT_PROPERTIES,
    ...defaultEventProperties,
  } as EventProperties;
};

export const getOrgSlug = (vcsType: string, orgName: string): string => {
  return `${vcsType}/${orgName}`;
};
