import { mediaDesktop } from '~/plans-and-payments/components/shared/viewPorts';
import styled from '~/styled';

export const OverviewCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.n70};
  margin-bottom: 24px;
  padding: 0 24px 12px 24px;
`;

export const OverviewCardHeading = styled.h2`
  color: ${({ theme }) => theme.colors.n900};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin: 20px 0 20px 0;
  letter-spacing: 0.1px;
`;

export const OverviewCardInfo = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.n70};
  color: ${({ theme }) => theme.colors.n400};
  display: flex;
  font-size: 16px;
  line-height: 18px;
  margin-top: 16px;
  padding: 16px 0 4px 0;
`;

export const OverviewShadowCard = styled.div`
  border-radius: ${({ theme }) => theme.legacy.space[2]}px;
  border: 1px solid ${({ theme }) => theme.colors.n80};
  box-shadow: 0 1px 8px 0 rgba(22, 22, 22, 0.05);
  margin-bottom: ${({ theme }) => theme.legacy.space[3]}px;
  padding: 0 ${({ theme }) => theme.legacy.space[4]}px
    ${({ theme }) => theme.legacy.space[4]}px
    ${({ theme }) => theme.legacy.space[4]}px;
  background-color: ${({ theme }) => theme.colors.n30};
`;

export const IconContainer = styled.span`
  margin-right: 8px;
`;

export const UsageBarContainer = styled.div`
  margin: 12px 10px 0 0;
`;

export const UsageBarAnnotations = styled.div`
  display: flex;
  justify-content: flex-end;

  span:first-of-type {
    display: none;
  }

  ${mediaDesktop} {
    justify-content: space-between;

    span:first-of-type {
      display: inherit;
    }
  }
`;

export const OverviewCardInnerInfo = styled(OverviewCardInfo)`
  border-top: 0;
  padding-top: 0;
`;
