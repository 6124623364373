import { client } from '@circleci/web-ui-data';

import { OrganizationInput } from '~/plans-and-payments/graphql/types';

type Fetch = typeof fetch;

export default async ({
  fetch,
  apiKey,
  parentOrganizationVcsType,
  parentOrganizationName,
  piggybackedOrganizations,
}: {
  fetch: Fetch;
  apiKey?: string;
  parentOrganizationVcsType: string;
  parentOrganizationName: string;
  piggybackedOrganizations: OrganizationInput[];
}) => {
  const pathname = `/api/v1.1/organization/${parentOrganizationVcsType}/${parentOrganizationName}/plan`;
  return await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'PUT',
    body: {
      'piggieback-org-maps': piggybackedOrganizations.map((org) => ({
        name: org.name,
        'vcs-type': org.vcsType,
      })),
    },
  });
};
