import React, { useMemo } from 'react';

import { useOrganization } from '@circleci/api-hooks/private';
import { useOrgContext } from '@circleci/web-ui-data';
import { RebrandNavBar } from '@circleci/web-ui-nav';

import { useMeContext } from '~/contexts/MeContext';
import { useMeCollaborations } from '@circleci/api-hooks/v2';
import { VCSType } from '~/plans-and-payments/types';

export const SideNav = (): JSX.Element => {
  const { vcsType: vcs, name, orgId: id } = useOrgContext();
  const orgName = name as string;
  const vcsType = vcs as VCSType;
  const organizationId = id as string;

  const { me } = useMeContext();
  const { isFetching: collaborationsLoading, data: collaborations } =
    useMeCollaborations();

  const { data: org } = useOrganization({
    vcsType,
    orgName,
  });
  const humanReadableOrgName = org?.name || orgName;
  const orgSlug = org?.slug || `${vcsType}/${orgName}`;

  const collaborationsWithCorrectTypes = useMemo(() => {
    if (!collaborations) {
      return [];
    }

    return collaborations.map((collaboration) => ({
      ...collaboration,
      id: collaboration.id || null,
      vcsType: collaboration.vcsType || '',
      name: collaboration.name || '',
      avatarUrl: collaboration.avatarUrl || null,
    }));
  }, [collaborations]);

  return (
    <RebrandNavBar
      me={me}
      collaborations={collaborationsWithCorrectTypes}
      collaborationsLoading={collaborationsLoading}
      selectedOrg={{
        id: organizationId,
        humanReadableOrgName,
        orgName,
        vcsType,
        slug: orgSlug,
      }}
      activeLinkName="Plan"
      orgLinksPrefix="settings/plan"
    />
  );
};
