import { client } from '@circleci/web-ui-data';
import { APIPersonalToken } from '~/user-settings/graphql/shim/types';

export default async (
  fetch: any,
  apiKey: string,
): Promise<APIPersonalToken[]> => {
  const pathname = '/api/v1/user/token';
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};
