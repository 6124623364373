export const small = 576;
export const medium = 768;
export const large = 992;
export const huge = 1200;

export const mediaMobile = `@media (max-width: ${small - 1}px)`;
export const mediaDesktop = `@media (min-width: ${small}px)`;
export const mediaTabletAndLarger = `@media (min-width: ${medium}px)`;
export const mediaLarge = `@media (min-width: ${large}px)`;
export const mediaHuge = `@media (min-width: ${huge}px)`;
