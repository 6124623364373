import React, { createContext, FunctionComponent, ReactNode } from 'react';

interface AnalyticsEventProperties {
  planTier: string;
  isPrepaid: boolean | null;
  isAdmin: boolean | null;
}

const AnalyticsEventPropertiesContext = createContext<AnalyticsEventProperties>(
  {} as AnalyticsEventProperties,
);

const AnalyticsEventPropertiesProvider: FunctionComponent<{
  eventProperties: AnalyticsEventProperties;
  children: ReactNode;
}> = ({ eventProperties, children }) => {
  return (
    <AnalyticsEventPropertiesContext.Provider value={eventProperties}>
      {children}
    </AnalyticsEventPropertiesContext.Provider>
  );
};

export { AnalyticsEventPropertiesProvider, AnalyticsEventPropertiesContext };
