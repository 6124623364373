import { graphQLErrorsHasUnauthorizedError } from '@circleci/web-ui-data/build/client/errors';
import * as React from 'react';
import * as url from 'url';

import { me } from '@circleci/web-ui-data';
import PageLoading from '~/components/shared-components/PageLoading';
import NotFound from '~/components/NotFound';
import { useRouter } from 'next/router';
import getInviteSignupUrl from '~/engagement-experiments/utils/getInviteSignupUrl';
import useTrackClickedSharedInviteLink from '~/engagement-experiments/hooks/useTrackClickedSharedInviteLink';
import { isAuthenticated } from '~/utils/permissions/authentication';
import { isUserConnectedToVcs } from '~/utils/isUserConnectedToVcs';

export interface MeContextProps {
  me?: Me;
  isEvaluator?: boolean;
  privacyOptOut: boolean;
}

const useMe = me.hook;
type Me = NonNullable<ReturnType<typeof me.hook>['me']>;

interface Props {
  children: React.ReactNode;
}

export const MeProvider = ({ children }: Props) => {
  const { loading, me, error } = useMe();
  const router = useRouter();

  const isInvited = !!router?.query?.invite;
  const isUnauthorized = error && graphQLErrorsHasUnauthorizedError(error);
  const isEvaluator = !isUserConnectedToVcs(me);

  useTrackClickedSharedInviteLink({
    loading,
    isInvited,
    isUnauthorized,
    page: router?.pathname,
  });

  if (loading) return <PageLoading />;
  // Me can be undefined when a user is unauthenticated and viewing
  // a public project.
  if (isUnauthorized && isAuthenticated(me)) {
    const returnTo = window.location.href;
    if (isInvited) {
      const redirectUrl = getInviteSignupUrl({
        returnTo,
      });
      window.location.assign(redirectUrl);
      return null;
    }
    window.location.assign(
      url.format({
        pathname: '/api/login',
        query: { 'return-to': returnTo },
      }),
    );
    return null;
  }
  // Same as above, Me can be undefined when a user is
  // unauthenticated and viewing a public project.
  if (error && isAuthenticated(me)) {
    return <NotFound />;
  }

  const privacyOptOut = !me || me.privacyOptout;

  return (
    <MeContext.Provider value={{ me, isEvaluator, privacyOptOut }}>
      {children}
    </MeContext.Provider>
  );
};

const MeContext = React.createContext<MeContextProps>(null as any);

export const useMeContext = () => React.useContext(MeContext);

export default MeContext;
