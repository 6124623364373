import { AppProps } from 'next/app';
import * as React from 'react';
import 'dotenv';

// Register the exception logger to catch all uncaught exceptions
import '~/utils/exceptionLogger';
// Initialize datadogRUM
import { initDatadogRUM } from '~/utils/realUserMonitoring';
import PageTemplate from '~/components/PageTemplate';
import isServer from '~/utils/isServer';
import { NextPage, NextPageContext } from 'next';
import { PageLayout } from '~/components/PageLayout';
import PlansAndPaymentsPageLayout from '~/plans-and-payments/components/shared/PageLayout';

initDatadogRUM();
interface Props extends AppProps {
  ctx: NextPageContext;
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const customPageTemplateRoutes = ['/launchpad', '/integrated-devops'];
const isCustomPageTemplateRoute = (route: string) =>
  customPageTemplateRoutes.some((customRoute) => route.startsWith(customRoute));

const navlessRoutes = [
  '/healthcheck',
  '/pipelines/healthcheck',
  '/new-error-page',
  '/error-page',
  'new-error-page',
  '/settings/project',
  '/settings/organization',
  '/launchpad',
  '/integrated-devops',
  '/runner',
  '/settings/user',
  '/insights',
];

const WebUI = ({ Component, pageProps, router }: AppPropsWithLayout) => {
  // We are merging MFEs into web-ui that do not have the Nav for now. PageLayout needs to know
  // whether to render the nav which we decide here depending on the route.
  const isNavless =
    navlessRoutes.includes(router?.asPath) ||
    navlessRoutes.some((route) => router?.asPath.startsWith(route));

  const isPlansAndPayments = ['/checkout', '/settings/plan'].some((route) =>
    router?.asPath.startsWith(route),
  );

  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return <></>;

  // if a component doesn't overwrite use the default layout which has sidebar
  function getLayout(page: React.ReactElement) {
    if (Component.getLayout) {
      return Component.getLayout(page);
    }
    if (isPlansAndPayments) {
      return <PlansAndPaymentsPageLayout>{page}</PlansAndPaymentsPageLayout>;
    }
    return (
      <PageLayout router={router} isNavless={isNavless}>
        {page}
      </PageLayout>
    );
  }

  if (isCustomPageTemplateRoute(router?.asPath)) {
    return <Component router={router} {...pageProps} />;
  }

  return isServer ? null : (
    <PageTemplate router={router}>
      {getLayout(<Component {...pageProps} />)}
    </PageTemplate>
  );
};

WebUI.getInitialProps = async ({ Component, ctx }: Props) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

export default WebUI;
