import { keyframes } from '@emotion/core';

import styled from '~/styled';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.legacy.space[6]}px;
  height: ${({ theme }) => theme.legacy.space[6]}px;

  :before {
    content: '';
    position: absolute;
    border: 10px solid transparent;
    border-color: ${({ theme }) => theme.colors.blue100};
    border-radius: 50%;
  }

  :after {
    content: '';
    position: absolute;
    border: 10px solid transparent;
    border-top-color: ${({ theme }) => theme.colors.blue500};
    border-right-color: ${({ theme }) => theme.colors.blue500};
    border-radius: 50%;
    animation: ${rotate} 3s infinite linear;
  }
`;

export default LoadingIcon;
