import { OverviewCardInfo } from '~/plans-and-payments/components/shared/usageCardElements';
import { mediaDesktop } from '~/plans-and-payments/components/shared/viewPorts';
import styled from '~/styled';

export const OverviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageName = styled.span`
  color: ${({ theme }) => theme.colors.n900};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;

  ${mediaDesktop} {
    flex-direction: row;
  }
`;

export const PlanCardHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaDesktop} {
    flex-direction: row;
  }
`;

export const PlanCardInfo = styled(OverviewCardInfo)`
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const PlanCardHeadingAction = styled.span`
  margin: 8px 0 16px 0;

  ${mediaDesktop} {
    margin: 16px 0 16px 0;
  }
`;
