import { client } from '@circleci/web-ui-data';
import { APICollaboration } from '~/user-settings/graphql/shim/types';

export default async (
  fetch: any,
  apiKey: string | undefined,
): Promise<APICollaboration[]> => {
  const pathname = '/api/v2/me/collaborations';
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};
