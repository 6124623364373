import {
  APIServiceDataObjectUsage,
  APIServiceProjectUsage,
  APIServiceUsageData,
  BillingPeriod,
} from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import {
  BillingPeriodRange,
  DataObjectUsageTable,
  ProjectUsageTable,
  ResourceClassMetric,
  Usage,
} from '~/plans-and-payments/graphql/types';

export default (props: APIServiceUsageData): Usage => {
  function getPeriod(billingPeriod: BillingPeriod): BillingPeriodRange {
    return {
      start: billingPeriod.period.start,
      end: billingPeriod.period.end,
    };
  }

  function getAggregateByResourceClass(
    project: APIServiceProjectUsage,
  ): ResourceClassMetric[] {
    return project.aggregateByResourceClass.map((resourceClassMetric) => ({
      ...resourceClassMetric,
      metric: {
        ...resourceClassMetric.metric,
        totalCredits: resourceClassMetric.metric.credits,
      },
    }));
  }

  function getProjects(billingPeriod: BillingPeriod): ProjectUsageTable[] {
    return billingPeriod.metrics.byProject.nodes.map(
      (project: APIServiceProjectUsage) => ({
        aggregateByResourceClass: getAggregateByResourceClass(project),
        credits: {
          computeCredits: project.aggregate.computeCredits,
          dlcCredits: project.aggregate.dlcCredits,
        },
        data: {
          ipRanges: project.ipRangesMetric,
          storageV2ByProject: project.aggregate.storageV2ByProject,
          networkV2ByProject: project.aggregate.networkV2ByProject,
        },
        name: project.project.name,
        username: project.project.username,
        seconds: project.aggregate.seconds,
      }),
    );
  }

  function getDataObjects(
    billingPeriod: BillingPeriod,
  ): DataObjectUsageTable[] {
    return billingPeriod.metrics.byDataObject.nodes.map(
      (dataObject: APIServiceDataObjectUsage) => ({
        name: dataObject.name,
        storageV2: dataObject.metric.storageV2,
        networkV2: dataObject.metric.networkV2,
      }),
    );
  }

  function getByResourceClass(
    billingPeriod: BillingPeriod,
  ): ResourceClassMetric[] {
    return billingPeriod.metrics.byProject.totalByResourceClass.map((r) => ({
      ...r,
      metric: {
        ...r.metric,
        computeCredits: r.metric.computeCredits,
        totalCredits: r.metric.credits,
      },
    }));
  }

  return {
    billingPeriods: props.plan.billingPeriods.map((billingPeriod) => ({
      activeUsers: billingPeriod.metrics.activeUsers.totalCount,
      totalCredits: billingPeriod.metrics.total.credits,
      computeCredits: billingPeriod.metrics.total.computeCredits,
      networkCredits: billingPeriod.metrics.total.networkCredits,
      storageCredits: billingPeriod.metrics.total.storageCredits,
      userCredits: billingPeriod.metrics.total.userCredits,
      dlcCredits: billingPeriod.metrics.total.dlcCredits,
      totalProjectsUsingDLC: billingPeriod.metrics.projects.totalCount,
      networkEgress: billingPeriod.metrics.total.networkEgress,
      storageV2: billingPeriod.metrics.total.storageV2,
      totalSeconds: billingPeriod.metrics.total.seconds,
      totalIpRanges: billingPeriod.metrics.total.ipRanges,
      ipRangesCredits: billingPeriod.metrics.total.ipRangesCredits,
      totalByResourceClass: getByResourceClass(billingPeriod),
      period: getPeriod(billingPeriod),
      projects: getProjects(billingPeriod),
      dataObjects: getDataObjects(billingPeriod),
      thresholds: billingPeriod.metrics.thresholds,
      leases: billingPeriod.metrics.leases,
    })),
  };
};
