import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIBillingPeriodData } from '~/plans-and-payments/graphql/shim/apiServiceTypes';

const query = gql`
  query BillingPeriods($orgId: String!) {
    plan(orgId: $orgId) {
      billingPeriods(numPeriods: 12) {
        period {
          start
          end
        }
      }
    }
  }
`;

export interface FetchBillingPeriod {
  orgId: string;
}

export default async (
  props: FetchBillingPeriod,
): Promise<APIBillingPeriodData> => {
  const res = await apiServiceClient.query<APIBillingPeriodData>({
    query,
    variables: props,
  });
  return res.data;
};
