import { client } from '@circleci/web-ui-data';
import { APIPersonalToken } from '~/user-settings/graphql/shim/types';

export const createAPIToken = async (
  fetch: any,
  apiKey: string,
  label: string,
): Promise<APIPersonalToken> => {
  const pathname = '/api/v1/user/token';
  const body = { label: label };
  const response = await client.apiMutator({ apiKey, fetch, pathname, body });
  return await response.json();
};

export const removeAPIToken = async (
  fetch: any,
  apiKey: string,
  tokenId: string,
): Promise<void> => {
  const pathname = `/api/v1/user/token/${tokenId}`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'DELETE',
    body: {},
  });
  return await response.json();
};
