import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import { MutationSignupArgs } from '~/plans-and-payments/graphql/types';

const mutation = gql`
  mutation signup($signupInput: SignupInput!) {
    signup(input: $signupInput) {
      success
      actionLink
    }
  }
`;

export interface APIServiceSignup {
  signup: {
    success: boolean;
    actionLink?: string;
  };
}

export default async (
  props: MutationSignupArgs,
): Promise<{ success: boolean }> => {
  const res = await apiServiceClient.mutate<APIServiceSignup>({
    mutation: mutation,
    variables: {
      signupInput: props.signupInput,
    },
  });

  return res.data ? res.data.signup : { success: false };
};
