import { APIMe } from '~/plans-and-payments/graphql/shim/fetchers/me/me';
import { APIOrganization } from '~/plans-and-payments/graphql/shim/fetchers/organizations-rest/organizations-rest';
import { APIServicePlanV2 } from '~/plans-and-payments/graphql/shim/fetchers/planV2/planV2';
import mapPlanTier from '~/plans-and-payments/graphql/shim/mappers/plan/mapPlanTier';
import {
  Organization,
  PlanShareAndTransfer,
} from '~/plans-and-payments/graphql/types';

export function mappedAdminOrgs(orgs: APIOrganization[]): Organization[] {
  return orgs
    .filter((o) => o.admin)
    .map((o) => ({
      name: o.login,
      vcsType: o.vcs_type,
    }));
}

export default function (
  me: APIMe,
  planV2: APIServicePlanV2,
  orgs: APIOrganization[],
): PlanShareAndTransfer {
  return {
    currentUser: {
      id: me.analytics_id,
      selectedEmail: me.selected_email,
      adminOrgs: mappedAdminOrgs(orgs),
    },
    planTier: mapPlanTier(planV2),
  };
}
