import React, { useContext } from 'react';

import { useFeatureFlag, useFeatureVariables } from '@circleci/feature-flags';
import { useOrgContext } from '@circleci/web-ui-data';

//type ABTestGroup = 'control' | 'treatment' | null;

// list of all feature flags live in production
export interface FeatureFlags {
  testInsightsSummaryComponentEnabled: boolean;
  numberOfTestExecutions: number;
  enableSortByTrend: boolean;
  enableOutlierDetection: boolean;
  maintenanceWindow: boolean;
  resourceClassInsightsEnabled: boolean;
  landingPageMaintenance: boolean;
  orgId: string | undefined;
}

const defaultFlags: FeatureFlags = {
  testInsightsSummaryComponentEnabled: false,
  numberOfTestExecutions: 10,
  enableSortByTrend: false,
  enableOutlierDetection: false,
  maintenanceWindow: false,
  resourceClassInsightsEnabled: false,
  landingPageMaintenance: false,
  orgId: undefined,
};

export const FeatureContext = React.createContext<FeatureFlags>(defaultFlags);
export const useFeatureContext = () => {
  return useContext(FeatureContext);
};

const FeatureContextProvider: React.FC = ({ children }) => {
  const { orgId, vcsType, name: orgName } = useOrgContext();

  const isDatadog =
    typeof window === 'undefined'
      ? false
      : (window as any)._DATADOG_SYNTHETICS_BROWSER === true;

  const { isFeatureEnabled: testInsightsSummaryComponentEnabled } =
    useFeatureFlag({
      featureName: 'test_insights_summary_component',
      attributes: {
        vcsType,
        orgName,
        $opt_bucketing_id: `${vcsType}_${orgName}`,
        isDatadog: isDatadog,
      },
    });

  const numberOfTestExecutions =
    useFeatureVariables({
      featureName: 'test_insights_100_executions',
      vcsType: vcsType as string,
      orgName: orgName as string,
      attributes: {
        vcsType,
        orgName,
        $opt_bucketing_id: `${vcsType}_${orgName}`,
        isDatadog: isDatadog,
      },
    }).featureVariables?.n_tests ?? 100;

  const { isFeatureEnabled: enableSortByTrend } = useFeatureFlag({
    featureName: 'insights_sort_trends',
    attributes: {
      vcsType,
      orgName,
      $opt_bucketing_id: `${vcsType}_${orgName}`,
      isDatadog: isDatadog,
    },
  });

  const { isFeatureEnabled: enableOutlierDetection } = useFeatureFlag({
    featureName: 'insights_recent_workflow_runs_outlier_detection',
    attributes: {
      vcsType,
      orgName,
      orgId,
      $opt_bucketing_id: `${vcsType}_${orgName}`,
      isDatadog: isDatadog,
    },
  });

  const { isFeatureEnabled: maintenanceWindow } = useFeatureFlag({
    featureName: 'insights_maintenance',
    attributes: {
      vcsType,
      orgName,
      orgId,
      $opt_bucketing_id: `${vcsType}_${orgName}`,
    },
  });

  const { isFeatureEnabled: resourceClassInsights } = useFeatureFlag({
    featureName: 'resource_class_insights',
    attributes: {
      vcsType,
      orgName,
      orgId,
      $opt_bucketing_id: `${vcsType}_${orgName}`,
    },
  });

  const { isFeatureEnabled: landingPageMaintenance } = useFeatureFlag({
    featureName: 'insights_landing_page_maintenance',
    attributes: {
      vcsType,
      orgName,
      orgId,
      $opt_bucketing_id: `${vcsType}_${orgName}`,
    },
  });

  const flags: FeatureFlags = {
    testInsightsSummaryComponentEnabled:
      testInsightsSummaryComponentEnabled as boolean,
    numberOfTestExecutions,
    enableSortByTrend: enableSortByTrend as boolean,
    enableOutlierDetection: enableOutlierDetection || false,
    maintenanceWindow: maintenanceWindow || false,
    resourceClassInsightsEnabled: resourceClassInsights || false,
    landingPageMaintenance: landingPageMaintenance || false,
    orgId: orgId,
  };

  return (
    <FeatureContext.Provider value={flags}>{children}</FeatureContext.Provider>
  );
};

export default FeatureContextProvider;
