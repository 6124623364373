import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

import {
  UpgradeError,
  UpgradeErrorType,
} from '~/plans-and-payments/graphql/types';

const convertError = (e: GraphQLError): UpgradeError => ({
  type:
    e.extensions && e.extensions['type'] === 'FAILED_PRECONDITION'
      ? UpgradeErrorType.FAILED_PRECONDITION
      : UpgradeErrorType.UNKNOWN,
  message: e.message,
});

export default (e: Error): UpgradeError[] => {
  if (e instanceof ApolloError) {
    return e.graphQLErrors.map(convertError);
  }
  return [{ type: UpgradeErrorType.UNKNOWN, message: e.message }];
};
