import { client } from '@circleci/web-ui-data';
import { APIOrganization } from '~/user-settings/graphql/shim/types';

export default async (
  fetch: any,
  apiKey: string,
): Promise<APIOrganization[]> => {
  const pathname = '/api/v1/user/organizations';
  const query = { 'include-user': 'true' };
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });

  return await response.json();
};
