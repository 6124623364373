import { ApolloError, gql, useQuery } from '@apollo/client';

import { PlanOverview as PlanOverviewOutput } from '~/plans-and-payments/graphql/parsedTypes';
import { PlanOverview, PlanTier } from '~/plans-and-payments/graphql/types';
import { VCSType } from '~/plans-and-payments/types';

export const PLAN_OVERVIEW_QUERY = gql`
  query PlanOverview($orgName: String!, $vcsType: String!) {
    planOverview(orgName: $orgName, vcsType: $vcsType) {
      currentUser {
        id
        selectedEmail
        adminOrgs {
          name
          vcsType
        }
      }
      plan {
        tier
        isTrial
        trialEnd
        subscriptionStatus
        supportTier
        billingProvider
      }
      upgradeSuggestions {
        computeCredits
        milliseconds
        userSeats
      }
    }
  }
`;

interface Data {
  planOverview: PlanOverview;
}
type Response = {
  planOverview: PlanOverviewOutput;
  refetch: () => void;
  loading: boolean;
  error: ApolloError | undefined;
};

const parsePlanOverview = (planOverview: PlanOverview): PlanOverviewOutput => {
  return {
    ...planOverview,
    upgradeSuggestions: {
      ...planOverview.upgradeSuggestions,
      computeCredits: parseInt(
        planOverview.upgradeSuggestions.computeCredits,
        10,
      ),
      milliseconds: parseInt(planOverview.upgradeSuggestions.milliseconds, 10),
    },
  };
};

const usePlanOverview = (props: {
  vcsType: VCSType;
  orgName: string;
}): Response => {
  const { loading, data, error, refetch } = useQuery<Data>(
    PLAN_OVERVIEW_QUERY,
    {
      variables: {
        orgName: props.orgName,
        vcsType: props.vcsType,
      },
    },
  );

  const planOverviewDefaults: PlanOverview = {
    currentUser: {
      id: '',
      selectedEmail: '',
      adminOrgs: [],
    },
    plan: {
      tier: PlanTier.FREE,
      billingProvider: '',
    },
    upgradeSuggestions: {
      computeCredits: '0',
      milliseconds: '0',
      userSeats: 0,
    },
  };

  return {
    planOverview: parsePlanOverview(
      data ? data.planOverview : planOverviewDefaults,
    ),
    refetch: (): void => {
      refetch();
    },
    loading,
    error,
  };
};

export default usePlanOverview;
