import { client } from '@circleci/web-ui-data';

export interface APIOrganization {
  admin: boolean;
  login: string;
  vcs_type: string;
}

const fetchOrganizationsRest = async (
  fetch: any,
  apiKey?: string,
): Promise<APIOrganization[]> => {
  const pathname = `/api/v1/user/organizations`;
  const query = { 'include-user': 'true' };
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return await response.json();
};
export default fetchOrganizationsRest;
