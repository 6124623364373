import apiServiceClient from '~/user-settings/graphql/apiServiceClient';
import gql from 'graphql-tag';

const mutation = gql`
  mutation RefreshUserPermissions {
    refreshUserPermissions {
      errors {
        type
      }
    }
  }
`;

const refreshUserPermissions = async () => {
  const response = await apiServiceClient.mutate({
    mutation,
    variables: {},
  });
  return response.data;
};
export default refreshUserPermissions;
