export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type APIServicePlan = {
  __typename?: 'APIServicePlan';
  createdAt: Scalars['String'];
  highWatermark: Scalars['Int'];
  id: Scalars['String'];
  isInactive: Scalars['Boolean'];
  lowWatermark: Scalars['Int'];
  tier: Scalars['String'];
};

export type ActiveContracts = {
  __typename?: 'ActiveContracts';
  main_contract: MainContract;
  term_end_date: Scalars['Date'];
  term_start_date: Scalars['Date'];
};

export type ActiveUser = {
  __typename?: 'ActiveUser';
  lastActiveDate: Scalars['Date'];
  user: User;
};

export type BillingPeriod = {
  __typename?: 'BillingPeriod';
  activeUsers: Scalars['Int'];
  computeCredits: Scalars['String'];
  dataObjects: Array<DataObjectUsageTable>;
  dlcCredits: Scalars['Int'];
  ipRangesCredits: Scalars['String'];
  leases: Leases;
  networkCredits: Scalars['String'];
  networkEgress: NetworkEgress;
  period: BillingPeriodRange;
  projects: Array<ProjectUsageTable>;
  storageCredits: Scalars['String'];
  storageV2: StorageV2;
  thresholds: Thresholds;
  totalByResourceClass?: Maybe<Array<ResourceClassMetric>>;
  totalCredits: Scalars['String'];
  totalIpRanges: Scalars['String'];
  totalProjectsUsingDLC: Scalars['Int'];
  totalSeconds: Scalars['Int'];
  userCredits: Scalars['Int'];
};

export type BillingPeriodRange = {
  __typename?: 'BillingPeriodRange';
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type BillingPortal = {
  __typename?: 'BillingPortal';
  href: Scalars['String'];
};

export enum CancelReason {
  UNPAID = 'UNPAID',
}

export type Card = {
  __typename?: 'Card';
  billingEmail: Scalars['String'];
  cardExpMonth: Scalars['Int'];
  cardExpYear: Scalars['Int'];
  cardLastFour: Scalars['String'];
  cardType: Scalars['String'];
};

export type Collaboration = {
  __typename?: 'Collaboration';
  avatarUrl: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  superAdmin: Scalars['Boolean'];
  vcsType: Scalars['String'];
};

export type ComponentUsage = {
  __typename?: 'ComponentUsage';
  credits: CreditComponentUsage;
  hasPerformanceSeats: Scalars['Boolean'];
  seats: SeatComponentUsage;
};

export type CouponValid = {
  __typename?: 'CouponValid';
  valid: Scalars['Boolean'];
};

export type CreditComponentUsage = {
  __typename?: 'CreditComponentUsage';
  available: Scalars['String'];
  ossUsed: Scalars['Int'];
  privateUsed: Scalars['Int'];
  subscription: Scalars['Int'];
  used: Scalars['String'];
};

export enum CreditType {
  LINUX_OSS = 'LINUX_OSS',
  LINUX_PRIVATE = 'LINUX_PRIVATE',
  OSX_OSS = 'OSX_OSS',
  OSX_PRIVATE = 'OSX_PRIVATE',
}

export type CreditUsage = {
  __typename?: 'CreditUsage';
  computeCredits: Scalars['String'];
  dlcCredits: Scalars['Int'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  adminOrgs: Array<Organization>;
  id: Scalars['String'];
  selectedEmail?: Maybe<Scalars['String']>;
};

export type DataObjectUsageTable = {
  __typename?: 'DataObjectUsageTable';
  name: Scalars['String'];
  networkV2: Scalars['String'];
  storageV2: Scalars['String'];
};

export type DataUsage = {
  __typename?: 'DataUsage';
  ipRanges?: Maybe<IpRanges>;
  networkV2ByProject: Scalars['String'];
  storageV2ByProject: Scalars['String'];
};

export type DateTimeRange = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type InitiateUpgradeToUsageInput = {
  billingId?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  creditPackages?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  orgId: Scalars['String'];
  userSeats?: InputMaybe<Scalars['Int']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amountDue: Scalars['Int'];
  currency: Scalars['String'];
  date: Scalars['Int'];
  id: Scalars['String'];
  paid: Scalars['Boolean'];
  periodEnd: Scalars['Date'];
  periodStart: Scalars['Date'];
};

export type IpRanges = {
  __typename?: 'IpRanges';
  bytes: Scalars['String'];
  credits: Scalars['String'];
  type: Scalars['String'];
};

export type Leases = {
  __typename?: 'Leases';
  count: Scalars['Int'];
  leaseCreditsCharged: Scalars['String'];
  overageCreditsCharged: Scalars['String'];
  overageMinutes: Scalars['Int'];
};

export type MainContract = {
  __typename?: 'MainContract';
  base_credits: Scalars['Int'];
  bonus_credits: Scalars['Int'];
  bonus_refill_credits: Scalars['Int'];
  cents_per_credit: Scalars['Float'];
  credits_cost_cents: Scalars['Int'];
  refill_credits: Scalars['Int'];
  refill_credits_cost_cents: Scalars['Int'];
  support_cost_cents: Scalars['Int'];
  term_end_date: Scalars['Date'];
  term_start_date: Scalars['Date'];
};

export type Mutation = {
  __typename?: 'Mutation';
  InitiateUpgradeToUsage: UpgradeToUsageResponse;
  SharePlan: SharePlanResponse;
  Signup: SignupPayload;
  TransferPlan: TransferPlanResponse;
  UnregisteredUsersUsageControl?: Maybe<UnregisteredUserUsageControlResponse>;
};

export type MutationInitiateUpgradeToUsageArgs = {
  initiateUpgradeToUsageInput: InitiateUpgradeToUsageInput;
};

export type MutationSharePlanArgs = {
  parentOrganizationName: Scalars['String'];
  parentOrganizationVcsType: Scalars['String'];
  piggybackedOrganizations: Array<OrganizationInput>;
};

export type MutationSignupArgs = {
  signupInput: SignupInput;
};

export type MutationTransferPlanArgs = {
  fromOrganizationName: Scalars['String'];
  fromOrganizationVcsType: Scalars['String'];
  toOrganization: OrganizationInput;
};

export type MutationUnregisteredUsersUsageControlArgs = {
  enabled: Scalars['Boolean'];
  orgId: Scalars['String'];
};

export type NetworkEgress = {
  __typename?: 'NetworkEgress';
  totalBytes: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  admin?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  vcsType: Scalars['String'];
};

export type PartialOrganization = {
  __typename?: 'PartialOrganization';
  admin?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  vcsType?: Maybe<Scalars['String']>;
};

export type OrganizationInput = {
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  vcsType: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endOfPageIndex: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startOfPageIndex: Scalars['Int'];
};

export type PlanDetails = {
  __typename?: 'PlanDetails';
  auto_refillable: Scalars['Boolean'];
  available_credits: Scalars['Int'];
  contract_details?: Maybe<Array<Maybe<ActiveContracts>>>;
  refill_amount: Scalars['Int'];
  refill_threshold: Scalars['Int'];
  scheduled_refills: Array<ScheduledRefill>;
  support_tier: Scalars['String'];
};

export type PlanMetric = {
  __typename?: 'PlanMetric';
  computeCredits: Scalars['String'];
  dlcCredits?: Maybe<Scalars['Int']>;
  seconds: Scalars['Int'];
  totalCredits: Scalars['String'];
};

export type PlanOverview = {
  __typename?: 'PlanOverview';
  currentUser: CurrentUser;
  plan: PlanOverviewPlan;
  upgradeSuggestions: UpgradeSuggestions;
};

export type PlanOverviewPlan = {
  __typename?: 'PlanOverviewPlan';
  billingProvider: Scalars['String'];
  cancelReason?: Maybe<CancelReason>;
  isTrial?: Maybe<Scalars['Boolean']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  supportTier?: Maybe<SupportTier>;
  tier: PlanTier;
  trialEnd?: Maybe<Scalars['String']>;
};

export type PlanShareAndTransfer = {
  __typename?: 'PlanShareAndTransfer';
  currentUser: CurrentUser;
  planTier: PlanTier;
};

export enum PlanTier {
  CONTAINER = 'CONTAINER',
  CUSTOM_CONTAINER = 'CUSTOM_CONTAINER',
  CUSTOM_PERFORMANCE = 'CUSTOM_PERFORMANCE',
  FREE = 'FREE',
  GITHUB_MARKETPLACE = 'GITHUB_MARKETPLACE',
  PERFORMANCE = 'PERFORMANCE',
  SCALE = 'SCALE',
}

export type PlanUsage = {
  __typename?: 'PlanUsage';
  plan: PlanUsagePlan;
};

export type PlanUsageFeature = {
  __typename?: 'PlanUsageFeature';
  quantity: Scalars['Int'];
  slug: Scalars['String'];
};

export type PlanUsagePlan = {
  __typename?: 'PlanUsagePlan';
  features: Array<PlanUsageFeature>;
  tier: PlanTier;
};

export type ProjectUsageTable = {
  __typename?: 'ProjectUsageTable';
  aggregateByResourceClass?: Maybe<Array<ResourceClassMetric>>;
  credits: CreditUsage;
  data: DataUsage;
  name: Scalars['String'];
  seconds: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  MembersCount?: Maybe<Scalars['Int']>;
  activeUsers: UsersPage;
  billingPeriods: Array<Maybe<BillingPeriodRange>>;
  billingPortal: BillingPortal;
  collaborations: Array<Collaboration>;
  componentUsage: ComponentUsage;
  couponCodeValid: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  org: Organization;
  organizations: Array<Maybe<Organization>>;
  plan: APIServicePlan;
  planOverview: PlanOverview;
  planShareAndTransfer: PlanShareAndTransfer;
  planUsage: PlanUsage;
  usage: Usage;
};

export type QueryMembersCountArgs = {
  orgName: Scalars['String'];
  vcsType: Scalars['String'];
};

export type QueryactiveUsersArgs = {
  dateRange: DateTimeRange;
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orgId: Scalars['String'];
};

export type QuerybillingPeriodsArgs = {
  orgId: Scalars['String'];
};

export type QuerybillingPortalArgs = {
  orgId: Scalars['String'];
};

export type QuerycomponentUsageArgs = {
  dateRange: DateTimeRange;
  orgId: Scalars['String'];
  planTier: PlanTier;
};

export type QuerycouponCodeValidArgs = {
  billingProvider: Scalars['String'];
  couponCode: Scalars['String'];
  orgId: Scalars['String'];
};

export type QueryenabledArgs = {
  orgId: Scalars['String'];
};

export type QueryorgArgs = {
  orgName: Scalars['String'];
  vcsType: Scalars['String'];
};

export type QueryplanArgs = {
  orgId: Scalars['String'];
};

export type QueryplanOverviewArgs = {
  orgName: Scalars['String'];
  vcsType: Scalars['String'];
};

export type QueryplanShareAndTransferArgs = {
  orgName: Scalars['String'];
  vcsType: Scalars['String'];
};

export type QueryplanUsageArgs = {
  orgName: Scalars['String'];
  vcsType: Scalars['String'];
};

export type QueryusageArgs = {
  offset: Scalars['Int'];
  orgId: Scalars['String'];
};

export type ResourceClassMetric = {
  __typename?: 'ResourceClassMetric';
  executor?: Maybe<Scalars['String']>;
  metric: PlanMetric;
  resourceClass: Scalars['String'];
};

export type ScheduledRefill = {
  __typename?: 'ScheduledRefill';
  base_credits: Scalars['Int'];
  bonus_credits: Scalars['Int'];
  cost_per_credit_cents: Scalars['Float'];
  credits_cost_cents: Scalars['Int'];
  effective_at: Scalars['Date'];
  provisioned: Scalars['Boolean'];
  total_credits: Scalars['Int'];
};

export type SeatComponentUsage = {
  __typename?: 'SeatComponentUsage';
  consumed: Scalars['Int'];
  freeTotal: Scalars['Int'];
  paidTotal: Scalars['Int'];
};

export enum ShareError {
  PAID_PLAN = 'PAID_PLAN',
  UNKNOWN = 'UNKNOWN',
}

export type SharePlanResponse = {
  __typename?: 'SharePlanResponse';
  error?: Maybe<ShareError>;
};

export type SignupInput = {
  chargifyToken: Scalars['String'];
  couponCode?: InputMaybe<Scalars['String']>;
  creditPackages: Scalars['Int'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  supportTier: Scalars['String'];
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type SignupPayload = {
  __typename?: 'SignupPayload';
  errors?: Maybe<Array<UpgradeError>>;
  success: Scalars['Boolean'];
};

export type StorageV2 = {
  __typename?: 'StorageV2';
  totalBytes: Scalars['String'];
};

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  PAST_DUE = 'PAST_DUE',
  PENDING = 'PENDING',
}

export enum SupportTier {
  ADVANCED = 'ADVANCED',
  CONTRACTED_STANDARD = 'CONTRACTED_STANDARD',
  FREE = 'FREE',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  PREMIUM = 'PREMIUM',
  SILVER = 'SILVER',
  STANDARD = 'STANDARD',
  STARTER = 'STARTER',
}

export type ThresholdBytes = {
  __typename?: 'ThresholdBytes';
  custom: Scalars['String'];
  free: Scalars['String'];
  performance: Scalars['String'];
  scale: Scalars['String'];
};

export type Thresholds = {
  __typename?: 'Thresholds';
  network: ThresholdBytes;
  storage: ThresholdBytes;
};

export enum TransferError {
  PAID_PLAN = 'PAID_PLAN',
  UNKNOWN = 'UNKNOWN',
}

export type TransferPlanResponse = {
  __typename?: 'TransferPlanResponse';
  error?: Maybe<TransferError>;
};

export type UnregisteredUserUsageControlResponse = {
  __typename?: 'UnregisteredUserUsageControlResponse';
  enabled: Scalars['Boolean'];
};

export type UpgradeError = {
  __typename?: 'UpgradeError';
  message: Scalars['String'];
  type: UpgradeErrorType;
};

export enum UpgradeErrorType {
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  UNKNOWN = 'UNKNOWN',
}

export type UpgradeSuggestions = {
  __typename?: 'UpgradeSuggestions';
  computeCredits: Scalars['String'];
  milliseconds: Scalars['String'];
  userSeats: Scalars['Int'];
};

export type UpgradeToUsageResponse = {
  __typename?: 'UpgradeToUsageResponse';
  errors?: Maybe<Array<UpgradeError>>;
  upgradeUrl?: Maybe<Scalars['String']>;
};

export type Usage = {
  __typename?: 'Usage';
  billingPeriods: Array<BillingPeriod>;
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UsersPage = {
  __typename?: 'UsersPage';
  activeUsers: Array<ActiveUser>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum VCSType {
  BITBUCKET = 'BITBUCKET',
  CIRCLECI = 'CIRCLECI',
  GITHUB = 'GITHUB',
}
