import { BadRequest } from '@circleci/web-ui-data/build/client/errors';

import fetchApiTokens from '~/project-settings/graphql/shim/fetchers/apiTokens';
import fetchCheckoutKeys from '~/project-settings/graphql/shim/fetchers/checkoutKeys';
import fetchEnvironmentVariables from '~/project-settings/graphql/shim/fetchers/environmentVariables';
import fetchProjectInfo from '~/project-settings/graphql/shim/fetchers/projectInfo';
import fetchProjectPrivacyStatus from '~/project-settings/graphql/shim/fetchers/projectPrivacyStatus';
import fetchProjects from '~/project-settings/graphql/shim/fetchers/projects';
import fetchProjectSettings from '~/project-settings/graphql/shim/fetchers/projectSettings';
import fetchUserOrganizations from '~/project-settings/graphql/shim/fetchers/userOrganizations';
import mapAdvancedSettings from '~/project-settings/graphql/shim/mappers/advancedSettings';
import {
  mapApiTokens,
  mapApiToken,
} from '~/project-settings/graphql/shim/mappers/apiTokens';
import mapCheckoutKeys from '~/project-settings/graphql/shim/mappers/checkoutKeys';
import mapCreateSshKey from '~/project-settings/graphql/shim/mappers/createSshKey';
import mapEnvironmentVariables from '~/project-settings/graphql/shim/mappers/environmentVariables';
import mapProjectDetails from '~/project-settings/graphql/shim/mappers/projectDetails';
import mapProjectPrivacyStatus from '~/project-settings/graphql/shim/mappers/projectPrivacyStatus';
import mapProjects from '~/project-settings/graphql/shim/mappers/projects';
import mapProjectSettings from '~/project-settings/graphql/shim/mappers/projectSettings';
import mutateAddEnvironmentVariable from '~/project-settings/graphql/shim/mutators/addEnvironmentVariable';
import mutateCreateApiToken from '~/project-settings/graphql/shim/mutators/createApiToken';
import mutateCreateCheckoutKey from '~/project-settings/graphql/shim/mutators/createCheckoutKey';
import mutateCreateSshKey from '~/project-settings/graphql/shim/mutators/createSshKey';
import mutateCreateWebhook from '~/project-settings/graphql/shim/mutators/createWebhook';
import mutateDeleteApiToken from '~/project-settings/graphql/shim/mutators/deleteApiToken';
import mutateDeleteCheckoutKey from '~/project-settings/graphql/shim/mutators/deleteCheckoutKey';
import mutateDeleteEnvironmentVariable from '~/project-settings/graphql/shim/mutators/deleteEnvironmentVariable';
import mutateImportEnvironmentVariables from '~/project-settings/graphql/shim/mutators/importEnvironmentVariables';
import mutatePingWebhook from '~/project-settings/graphql/shim/mutators/pingWebhook';
import mutateStopBuilding from '~/project-settings/graphql/shim/mutators/stopBuilding';
import mutateAdvancedSetting from '~/project-settings/graphql/shim/mutators/updateAdvancedSetting';
import mutateUpdateWebhook from '~/project-settings/graphql/shim/mutators/updateWebhook';
import {
  Resolver,
  QueryToProjectSettingsArgs,
  MutationToStopBuildingArgs,
  QueryToAdvancedSettingsArgs,
  MutationToUpdateSetGithubStatusArgs,
  MutationToUpdateBuildForkPrsArgs,
  MutationToUpdateForksReceiveSecretEnvVarsArgs,
  MutationToUpdateBuildPrsOnlyArgs,
  MutationToUpdateAutoCancelBuildsArgs,
  MutationToUpdateOssArgs,
  MutationToUpdateSetupWorkflowsArgs,
  QueryToEnvironmentVariablesArgs,
  MutationToAddEnvironmentVariableArgs,
  MutationToDeleteEnvironmentVariableArgs,
  MutationToDeleteCheckoutKeyArgs,
  MutationToCreateApiTokenArgs,
  QueryToApiTokensArgs,
  MutationToDeleteApiTokenArgs,
  QueryToCheckoutKeysArgs,
  MutationToCreateDeployKeyArgs,
  MutationToCreateUserKeyArgs,
  MutationToCreateSshKeyArgs,
  QueryToSshKeysArgs,
  MutationToDeleteSshKeyArgs,
  QueryToProjectsArgs,
  MutationToDeleteAWSVarsArgs,
  MutationToImportEnvironmentVariablesArgs,
  QueryToWebhooksArgs,
  QueryToWebhookArgs,
  MutationToDeleteWebhookArgs,
  MutationToPingWebhookArgs,
  MutationToCreateWebhookArgs,
  MutationToUpdateWebhookArgs,
  AddEnvironmentVariableError,
  QueryToProjectDetailsArgs,
  QueryToProjectPrivacyStatusArgs,
  QueryToWebhookFeaturesArgs,
  SshKey,
} from '~/project-settings/graphql/types';

import fetchProjectDetails from './fetchers/projectDetails';
import fetchWebhook from './fetchers/webhook';
import fetchWebhookFeatures from './fetchers/webhookFeatures';
import fetchWebhooks from './fetchers/webhooks';
import mapSshKeys from './mappers/sshKeys';
import mutateDeleteAWSVars from './mutators/deleteAWSVars';
import mutateDeleteSshKey from './mutators/deleteSshKey';
import mutateDeleteWebhook from './mutators/deleteWebhook';

interface Deps {
  apiKey: string;
  fetch: typeof window.fetch;
}

const resolvers: Resolver = {
  Query: {
    advancedSettings: async (
      _root: any,
      props: QueryToAdvancedSettingsArgs,
      deps: Deps,
    ) => {
      const projectSettings = await fetchProjectSettings({ ...deps, ...props });
      return mapAdvancedSettings(projectSettings);
    },
    projectDetails: async (
      _root: any,
      props: QueryToProjectDetailsArgs,
      deps: Deps,
    ) => {
      const projectDetails = await fetchProjectDetails({ ...deps, ...props });
      return mapProjectDetails(projectDetails);
    },
    projectSettings: async (
      _root: any,
      props: QueryToProjectSettingsArgs,
      deps: Deps,
    ) => {
      const projectSettings = await fetchProjectSettings({ ...deps, ...props });
      const userOrganizations = await fetchUserOrganizations({ ...deps });
      return mapProjectSettings(props, projectSettings, userOrganizations);
    },
    projectPrivacyStatus: async (
      _root: any,
      props: QueryToProjectPrivacyStatusArgs,
      deps: Deps,
    ) => {
      const projectPrivacyStatus = await fetchProjectPrivacyStatus({
        ...deps,
        ...props,
      });
      return mapProjectPrivacyStatus(projectPrivacyStatus);
    },
    webhooks: async (_root: any, props: QueryToWebhooksArgs, deps: Deps) =>
      (await fetchWebhooks({ ...deps, ...props })).items,
    webhook: async (_root: any, props: QueryToWebhookArgs, deps: Deps) =>
      fetchWebhook({ ...deps, ...props }),
    webhookFeatures: async (
      _root: any,
      props: QueryToWebhookFeaturesArgs,
      deps: Deps,
    ) => fetchWebhookFeatures({ ...deps, ...props }),
    environmentVariables: async (
      _root: any,
      props: QueryToEnvironmentVariablesArgs,
      deps: Deps,
    ) => {
      const environmentVariables = await fetchEnvironmentVariables({
        ...deps,
        ...props,
      });
      return mapEnvironmentVariables(environmentVariables);
    },
    apiTokens: async (_root: any, props: QueryToApiTokensArgs, deps: Deps) => {
      const apiTokens = await fetchApiTokens({ ...deps, ...props });
      return mapApiTokens(apiTokens);
    },
    checkoutKeys: async (
      _root: any,
      props: QueryToCheckoutKeysArgs,
      deps: Deps,
    ) => {
      const response = await fetchCheckoutKeys({ ...deps, ...props });
      return mapCheckoutKeys(response);
    },
    sshKeys: async (
      _root: any,
      props: QueryToSshKeysArgs,
      deps: Deps,
    ): Promise<SshKey[]> => {
      let response;
      try {
        response = await fetchProjectSettings({ ...deps, ...props });
      } catch (error) {
        if (error instanceof BadRequest) {
          return [];
        }
        throw error;
      }
      return mapSshKeys(response);
    },
    projects: async (
      _root: any,
      { vcsType, orgName }: QueryToProjectsArgs,
      { fetch, apiKey }: Deps,
    ) => {
      const response = await fetchProjects({ fetch, apiKey });
      return mapProjects(response, orgName, vcsType);
    },
  },

  Mutation: {
    addEnvironmentVariable: async (
      _root: any,
      props: MutationToAddEnvironmentVariableArgs,
      deps: Deps,
    ) => {
      try {
        await mutateAddEnvironmentVariable({ ...deps, ...props });
      } catch (error) {
        if (error instanceof BadRequest) {
          return {
            errors: [AddEnvironmentVariableError.BAD_REQUEST],
            message: error.message,
          };
        }
        throw error;
      }
      return { errors: [] };
    },

    deleteEnvironmentVariable: async (
      _root: any,
      props: MutationToDeleteEnvironmentVariableArgs,
      deps: Deps,
    ) => {
      await mutateDeleteEnvironmentVariable({ ...deps, ...props });
      return null;
    },

    importEnvironmentVariables: async (
      _root: any,
      {
        vcsType,
        orgName,
        projectName,
        fromProjectName,
        environmentVariableNames,
      }: MutationToImportEnvironmentVariablesArgs,
      deps: Deps,
    ) => {
      const info = await fetchProjectInfo({
        ...deps,
        vcsType,
        orgName,
        projectName,
      });
      await mutateImportEnvironmentVariables({
        ...deps,
        vcsType,
        orgName,
        fromProjectName,
        toVcsUrl: info.vcs_url ?? '',
        environmentVariableNames,
      });
      return null;
    },

    deleteCheckoutKey: async (
      _root: any,
      props: MutationToDeleteCheckoutKeyArgs,
      deps: Deps,
    ) => {
      await mutateDeleteCheckoutKey({ ...deps, ...props });
      return null;
    },

    stopBuilding: async (
      _root: any,
      props: MutationToStopBuildingArgs,
      deps: Deps,
    ) => {
      await mutateStopBuilding({ ...deps, ...props });
      return null;
    },

    updateSetGithubStatus: async (
      _root: any,
      props: MutationToUpdateSetGithubStatusArgs,
      deps: Deps,
    ) => {
      const featureFlag = { 'set-github-status': props.enabled };
      await mutateAdvancedSetting({ ...deps, ...props }, featureFlag);
      return null;
    },

    updateBuildForkPrs: async (
      _root: any,
      props: MutationToUpdateBuildForkPrsArgs,
      deps: Deps,
    ) => {
      const featureFlag = { 'build-fork-prs': props.enabled };
      await mutateAdvancedSetting({ ...deps, ...props }, featureFlag);
      return null;
    },

    updateForksReceiveSecretEnvVars: async (
      _root: any,
      props: MutationToUpdateForksReceiveSecretEnvVarsArgs,
      deps: Deps,
    ) => {
      const featureFlag = { 'forks-receive-secret-env-vars': props.enabled };
      await mutateAdvancedSetting({ ...deps, ...props }, featureFlag);
      return null;
    },
    updateBuildPrsOnly: async (
      _root: any,
      props: MutationToUpdateBuildPrsOnlyArgs,
      deps: Deps,
    ) => {
      const featureFlag = { 'build-prs-only': props.enabled };
      await mutateAdvancedSetting({ ...deps, ...props }, featureFlag);
      return null;
    },
    updateAutoCancelBuilds: async (
      _root: any,
      props: MutationToUpdateAutoCancelBuildsArgs,
      deps: Deps,
    ) => {
      const featureFlag = { 'autocancel-builds': props.enabled };
      await mutateAdvancedSetting({ ...deps, ...props }, featureFlag);
      return null;
    },
    updateOss: async (
      _root: any,
      props: MutationToUpdateOssArgs,
      deps: Deps,
    ) => {
      const featureFlag = { oss: props.enabled };
      await mutateAdvancedSetting({ ...deps, ...props }, featureFlag);
      return null;
    },
    updateSetupWorkflows: async (
      _root: any,
      props: MutationToUpdateSetupWorkflowsArgs,
      deps: Deps,
    ) => {
      const featureFlag = { 'setup-workflows': props.enabled };
      await mutateAdvancedSetting({ ...deps, ...props }, featureFlag);
      return null;
    },
    createApiToken: async (
      _root: any,
      props: MutationToCreateApiTokenArgs,
      deps: Deps,
    ) => {
      const result = await mutateCreateApiToken({ ...deps, ...props });
      return mapApiToken(result);
    },
    deleteApiToken: async (
      _root: any,
      props: MutationToDeleteApiTokenArgs,
      deps: Deps,
    ) => {
      await mutateDeleteApiToken({ ...deps, ...props });
      return null;
    },
    createDeployKey: async (
      _root: any,
      props: MutationToCreateDeployKeyArgs,
      deps: Deps,
    ) => {
      await mutateCreateCheckoutKey({
        ...deps,
        ...props,
        keyType: 'deploy',
      });
      return null;
    },
    createUserKey: async (
      _root: any,
      props: MutationToCreateUserKeyArgs,
      deps: Deps,
    ) => {
      await mutateCreateCheckoutKey({
        ...deps,
        ...props,
        keyType: 'user',
      });
      return null;
    },
    createSshKey: async (
      _root: any,
      props: MutationToCreateSshKeyArgs,
      deps: Deps,
    ) => {
      let response;
      try {
        response = await mutateCreateSshKey({ ...deps, ...props });
      } catch (error) {
        if (error instanceof BadRequest) {
          return mapCreateSshKey(error);
        }
        throw error;
      }
      return mapCreateSshKey(response);
    },
    deleteSshKey: async (
      _root: any,
      props: MutationToDeleteSshKeyArgs,
      deps: Deps,
    ) => {
      try {
        await mutateDeleteSshKey({ ...deps, ...props });
      } catch (error) {
        if (error instanceof BadRequest) {
          return null;
        }
        throw error;
      }

      return null;
    },
    deleteAWSVars: async (
      _root: any,
      props: MutationToDeleteAWSVarsArgs,
      deps: Deps,
    ) => {
      await mutateDeleteAWSVars({ ...deps, ...props });
      return null;
    },
    createWebhook: async (
      _root: any,
      props: MutationToCreateWebhookArgs,
      deps: Deps,
    ) => mutateCreateWebhook({ ...deps, ...props }),
    updateWebhook: async (
      _root: any,
      props: MutationToUpdateWebhookArgs,
      deps: Deps,
    ) => mutateUpdateWebhook({ ...deps, ...props }),
    deleteWebhook: async (
      _root: any,
      props: MutationToDeleteWebhookArgs,
      deps: Deps,
    ) => {
      await mutateDeleteWebhook({ ...deps, ...props });
      return null;
    },
    pingWebhook: async (
      _root: any,
      props: MutationToPingWebhookArgs,
      deps: Deps,
    ) => {
      await mutatePingWebhook({ ...deps, ...props });
      return null;
    },
  },
};

export default resolvers;
