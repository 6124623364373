export class CustomError {
  constructor(message: string, metadata: any = {}) {
    const error = new Error(message);

    // The as any cast is annoying, but Rollbar lets you associate arbitrary data with the error
    // https://rollbar.com/guides/javascript/how-to-throw-exceptions-in-javascript/#custom-exceptions
    (error as any).metadata = metadata;

    return error;
  }
}
