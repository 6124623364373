import { client } from '@circleci/web-ui-data';
import { isForbidden } from '@circleci/web-ui-data/build/client/errors';

export interface FetchMembersCount {
  vcsType: string;
  orgName: string;
}

export interface FetchMembersCountResponse {
  count: number;
}

interface Props extends FetchMembersCount {
  apiKey?: string;
  fetch: typeof window.fetch;
}

const fetchAPIMembersCount = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
}: Props) => {
  const vcs = encodeURIComponent(vcsType);
  const org = encodeURIComponent(orgName);

  const pathname = `/api/v1.1/organization/${vcs}/${org}/members-count`;

  try {
    const res = await client.apiFetcher({ apiKey, fetch, pathname });
    return res.json() as FetchMembersCountResponse;
  } catch (error: any) {
    if (isForbidden(error)) {
      return null;
    }
    throw error;
  }
};

export default fetchAPIMembersCount;
