import { client } from '@circleci/web-ui-data';

export interface Data {
  plan: {
    tier: string;
    highWatermark: number | null;
    isInactive: boolean;
    id: string;
    lowWatermark: number | null;
    createdAt: string;
  };
}

interface Props {
  orgId?: string;
}

interface APIServicePlanResponse {
  tier: string;
  'high-water-mark': number | null;
  'is-inactive': boolean;
  id: string;
  'low-water-mark': number | null;
  'created-at': string;
}

const apiServicePlan = async ({
  orgId,
}: Props): Promise<APIServicePlanResponse> => {
  const pathname = `/private/orgs/${orgId}/plan`;

  const defaults: APIServicePlanResponse = {
    tier: '',
    'high-water-mark': 0,
    'low-water-mark': 0,
    'created-at': '',
    'is-inactive': false,
    id: '',
  };

  if (orgId) {
    let response;
    try {
      const rawResponse = await client.apiFetcher({
        fetch,
        pathname,
      });
      response = await rawResponse.json();
    } catch (e) {
      response = {};
    }
    return response.plan || defaults;
  } else {
    return defaults;
  }
};
export default apiServicePlan;
