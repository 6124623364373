import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

export default async ({
  apiKey,
  fetch,
  orgId,
  enabled,
}: {
  apiKey?: string;
  fetch: Fetch;
  orgId: string;
  enabled: boolean;
}) => {
  const pathname = `/private/orgs/${orgId}/features/block-unregistered-users`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'PUT',
    body: { enabled },
  });
  return await response.json();
};
