import { Forbidden } from '@circleci/web-ui-data/build/client/errors';

import exceptionLogger from '~/plans-and-payments/frontplane/exceptionLogger';
import {
  ShareError,
  SharePlanResponse,
} from '~/plans-and-payments/graphql/types';

const paidPlanError = (error: Error) =>
  error instanceof Forbidden &&
  error.message.includes('Cannot share with paid plan');

const logUnknownError = (error: Error) => {
  exceptionLogger.warn('API error when sharing a plan', {
    error: error.message,
  });
};

export default (error: Error): SharePlanResponse => {
  if (paidPlanError(error)) {
    return { error: ShareError.PAID_PLAN };
  }
  logUnknownError(error);
  return { error: ShareError.UNKNOWN };
};
