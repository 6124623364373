import {
  APISettings,
  IntegrationSettings,
} from '~/user-settings/graphql/shim/types';

export default ({ identities }: APISettings): IntegrationSettings => ({
  github: identities.github
    ? ({
        isAuthorized: identities.github['authorized?'],
        login: identities.github.login,
      } as any)
    : ({
        isAuthorized: false,
        login: null,
      } as any),
  bitbucket: identities.bitbucket
    ? ({
        isAuthorized: identities.bitbucket['authorized?'],
        login: identities.bitbucket.login,
      } as any)
    : ({
        isAuthorized: false,
        login: null,
      } as any),
});
