import React, { cloneElement, Children, ReactNode, ReactElement } from 'react';

import { useOrgContext } from '@circleci/web-ui-data';
import { css } from '@emotion/core';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { PlanTier } from '~/plans-and-payments/graphql/types';
import styled from '~/styled';

export enum PageName {
  _ = '',
  OVERVIEW = 'overview',
  BILLING = 'billing',
  MANAGE = 'manage',
  USAGE = 'usage',
  USAGE_CONTROLS = 'usage-controls',
  SHARE = 'share',
}

const NavLinkList = styled.ol(({ theme }) => {
  return css`
    display: inline-flex;
    gap: ${theme.space.space16};
    list-style: none;
    border-bottom: 1px solid ${theme.colors.n80};
    margin: 0;
    padding: 0;
    width: fit-content;
  `;
});

const StyledAnchor = styled.a(({ theme }) => {
  return css`
    display: block;
    padding: ${theme.space.space12};
    font-size: ${theme.fontSizes.fontsize14};
    line-height: ${theme.lineHeights.lineheight24};
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;

    ::before {
      display: block;
      height: 0;
      content: attr(data-display-name);
      font-weight: ${theme.fontWeights.bold};
      overflow: hidden;
      visibility: hidden;
    }
  `;
});

const ListItem = styled.li<{ active?: boolean }>(({ theme, active }) => {
  return css`
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 5px solid ${active ? theme.colors.blue500 : 'transparent'};
    font-weight: ${active ? `${theme.fontWeights.bold}` : null};

    a {
      color: ${active ? `${theme.colors.n900}` : `${theme.colors.n400}`};
    }

    :hover,
    :focus {
      a {
        color: ${theme.colors.n900};
        font-weight: ${theme.fontWeights.bold};
      }
    }
  `;
});

interface ComposedLinkProps {
  href: string;
  children: ReactNode;
}

const ComposedLink = ({ href, children }: ComposedLinkProps): ReactElement => {
  const child = Children.only(children);
  const router = useRouter();

  return (
    <Link href={href} passHref legacyBehavior>
      {cloneElement(child as ReactElement, {
        'aria-current': router.pathname === href ? 'page' : null,
      })}
    </Link>
  );
};

class NavLink {
  href: string;
  displayName: string;

  constructor(href: string, displayName: string) {
    this.href = href;
    this.displayName = displayName;
  }
}

interface Props {
  showUsageControls: boolean;
  planTier: PlanTier;
}

export interface PresentationProps extends Props {
  vcsType: string;
  orgName: string;
}

const generateNavLinks = (
  navLinkRoot: string,
  planTier: PlanTier,
  showUsageControls: boolean,
): NavLink[] => {
  const isPerformance = planTier === PlanTier.PERFORMANCE;
  const links: NavLink[] = [];
  links.push(new NavLink(navLinkRoot + `/${PageName.OVERVIEW}`, 'overview'));
  if (isPerformance) {
    links.push(new NavLink(navLinkRoot + `/${PageName.MANAGE}`, 'manage'));
  }
  links.push(new NavLink(navLinkRoot + `/${PageName.USAGE}`, 'usage'));
  if (showUsageControls) {
    links.push(
      new NavLink(
        navLinkRoot + `/${PageName.USAGE_CONTROLS}`,
        'usage controls',
      ),
    );
  }
  if (isPerformance) {
    links.push(new NavLink(navLinkRoot + `/${PageName.BILLING}`, 'billing'));
  }
  links.push(
    new NavLink(navLinkRoot + `/${PageName.SHARE}`, `share & transfer`),
  );
  return links;
};

export const HorizontalTabNavPresentation = ({
  showUsageControls,
  planTier,
  vcsType,
  orgName,
}: PresentationProps): ReactElement => {
  const router = useRouter();
  const navLinkRoot = `/settings/plan/${vcsType}/${orgName}`;
  const currentPath = router.asPath.split('?')[0];

  const links: NavLink[] = generateNavLinks(
    navLinkRoot,
    planTier,
    showUsageControls,
  );
  return (
    <NavLinkList>
      {links.map((l, i) => {
        return (
          <ListItem active={currentPath === l.href} key={i}>
            <ComposedLink href={l.href}>
              <StyledAnchor data-display-name={l.displayName}>
                {l.displayName}
              </StyledAnchor>
            </ComposedLink>
          </ListItem>
        );
      })}
    </NavLinkList>
  );
};

const HorizontalTabNav = (props: Props): ReactElement => {
  const { vcsType, name } = useOrgContext();
  return (
    <HorizontalTabNavPresentation
      {...props}
      vcsType={vcsType as string}
      orgName={name as string}
    />
  );
};

export default HorizontalTabNav;
