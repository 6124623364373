import { ProjectV2 } from '@circleci/web-ui-data/build/queries/project/types';
import { VCSUrl } from '@circleci/web-ui-data/build/types/types';
import { APIActor } from '@circleci/web-ui-nav/dist/src/graphql/shim/types';
import * as R from 'ramda';
import { PipelineActor, VCSType } from '~/graphql/shim/types';
import { OrgSlug, TriggerAdapter, TriggerParameters } from '~/types/types';
import { ProjectSlug } from './navigation/routes';
import { ProjectSlug as ProjectSlugType } from '@circleci/web-ui-data/build/types/types';
import convertVcsType from './vcs/convertVcsType';
import getHostname from './getHostname';

export function getActorData(
  triggerActor: APIActor | undefined,
  triggerParameters: TriggerParameters,
): PipelineActor {
  // Still defaulting to Gitlab in case of safety.
  // Eventually when non-standalone projects use the triggerParameters too we can replace this.
  const triggerType = getTriggerType(triggerParameters);
  return {
    avatarUrl:
      triggerActor?.avatar_url ||
      R.pathOr<string>(
        undefined as any as string,
        [triggerType, 'user_avatar'],
        triggerParameters,
      ),
    name:
      triggerActor?.login ||
      R.pathOr<string>(
        '',
        [triggerType, 'commit_author_name'],
        triggerParameters,
      ),
  };
}

export function getTriggerType(
  triggerParameters: TriggerParameters,
): TriggerAdapter {
  return triggerParameters?.circleci?.trigger_type || 'gitlab';
}

const DECOUPLED_VCS = 'circleci';

export function isDecoupledProject(vcsType: VCSType): boolean {
  return vcsType === DECOUPLED_VCS;
}

export function isIdenticalProject(
  projectv2Info: ProjectV2,
  project: ProjectSlug,
): boolean {
  const projectDetails = getProjectDetailsFromVCSUrl(
    projectv2Info?.vcsInfo?.vcsUrl,
  );

  return (
    projectDetails.projectName === project.project &&
    projectDetails.orgName === project.username
  );
}

function getProjectDetailsFromVCSUrl(vcsURL: VCSUrl) {
  // This is not ideal and should be the only place in web-ui where a vcsURL gets parsed,
  // out of necessity.
  const splitProjectURL = R.takeLast(2, R.split('/', vcsURL));
  // These can be uuid for decoupled projects, see VCSUrl type
  const [orgId, projectId] = splitProjectURL;

  return {
    projectName: projectId,
    orgName: orgId,
  };
}

export function parseSlug(
  slug: ProjectSlugType | OrgSlug,
): [vcsType: string, org: string, project: string] {
  // In case there are trailing '/'
  const [vscType, org, project] = slug.split('/').filter((n) => n);

  return [vscType, org, project];
}

function startsWithShortVCS(slug: ProjectSlugType | OrgSlug): boolean {
  return slug.startsWith('gh') || slug.startsWith('bb');
}

// This file should be the only place in the repository where slugs get operated on
// comparison, parsing etc..
export function areIdenticalSlugs(
  firstSlug: ProjectSlugType | OrgSlug,
  secondSlug: ProjectSlugType,
): boolean {
  if (!firstSlug || !secondSlug) return false;

  // Slugs can start with the ShortVCS, which is annoying for comparison
  const sanitizedFirstSlug = startsWithShortVCS(firstSlug)
    ? convertVcsType(firstSlug)!
    : firstSlug;
  const sanitizedSecondSlug = startsWithShortVCS(secondSlug)
    ? convertVcsType(secondSlug)!
    : secondSlug;

  // The second slug can be with short VCSType so we need to convert it first
  // Because of the useCrossProjectPipelines, we consider
  // slugs with identical vcsType/Orgs
  // Check if orgSlug of type gh/circleci or circlecile/5TAfCCBjLchj2MTDnK8qLh
  if (firstSlug.split('/').filter((n) => n).length === 2) {
    const [firstVCS, firstOrg] = parseSlug(sanitizedFirstSlug);
    const [secondVCS, secondOrg] = parseSlug(sanitizedSecondSlug);

    return firstVCS === secondVCS && firstOrg === secondOrg;
  }

  return sanitizedFirstSlug === sanitizedSecondSlug;
}
interface ComputeProjectURLProps {
  vcsType: VCSType;
  projectSlug: ProjectSlugType | undefined;
}

export function computeProjectSetUpConfigURL({
  vcsType,
  projectSlug,
}: ComputeProjectURLProps): string {
  const coupledProjectUrl = `https://app.${getHostname()}/projects/project-setup/${convertVcsType(
    projectSlug || '',
  )}`;
  const projectSettingUrl = `https://app.${getHostname()}/settings/project/${
    projectSlug || ''
  }/configurations`;
  return isDecoupledProject(vcsType) ? projectSettingUrl : coupledProjectUrl;
}
