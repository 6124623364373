import * as React from 'react';
import { SuccessIcon, WarningIcon } from '@circleci/compass';
import useOpenClose from '~/hooks/misc/useOpenClose';
import useConnectivityStatus, {
  ConnectivityStatus,
} from '~/hooks/misc/useConnectivityStatus';
import Toast, {
  ToastContent,
  ToastCloseButton,
} from '~/components/shared-components/Toast';

export const MESSAGES = {
  OFFLINE: {
    message: 'No Internet Connection',
    icon: <WarningIcon color={'red300'} />,
  },
  RECONNECTED: {
    message: 'Reconnected to Internet.  Please refresh for updates.',
    icon: <SuccessIcon color={'green300'} />,
  },
};

export interface PresentationProps {
  status: ConnectivityStatus.OFFLINE | ConnectivityStatus.RECONNECTED;
  close: () => void;
}

export const Presentation = ({ status, close }: PresentationProps) => {
  return (
    <Toast>
      {MESSAGES[status].icon}
      <ToastContent>{MESSAGES[status].message}</ToastContent>
      <ToastCloseButton onClick={close} />
    </Toast>
  );
};

export const ConnectivityToast = () => {
  const connectivityStatus = useConnectivityStatus();
  const { isOpen, close, open } = useOpenClose();

  React.useEffect(() => {
    if (connectivityStatus === ConnectivityStatus.OFFLINE) {
      open();
    }
    if (connectivityStatus === ConnectivityStatus.RECONNECTED) {
      open();
    }
  }, [connectivityStatus]);

  if (!isOpen) return null;

  const status =
    connectivityStatus === ConnectivityStatus.OFFLINE
      ? ConnectivityStatus.OFFLINE
      : ConnectivityStatus.RECONNECTED;
  return <Presentation status={status} close={close} />;
};

export default ConnectivityToast;
