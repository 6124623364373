import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import { MutationInitiateUpgradeToUsageArgs } from '~/plans-and-payments/graphql/types';

const mutation = gql`
  mutation initiateUpgradeToUsage(
    $initiateUpgradeToUsageInput: InitiateUpgradeToUsageInput!
  ) {
    initiateUpgradeToUsage(input: $initiateUpgradeToUsageInput) {
      upgradeUrl
    }
  }
`;

export interface APIServiceInitiateUpgradeToUsage {
  initiateUpgradeToUsage: {
    upgradeUrl: string;
  };
}

export default async (
  props: MutationInitiateUpgradeToUsageArgs,
): Promise<{ upgradeUrl: string } | undefined> => {
  const res = await apiServiceClient.mutate<APIServiceInitiateUpgradeToUsage>({
    mutation: mutation,
    variables: {
      initiateUpgradeToUsageInput: props.initiateUpgradeToUsageInput,
    },
  });

  return res.data?.initiateUpgradeToUsage;
};
