import { makeExecutableSchema } from '@graphql-tools/schema';
import {
  me,
  orgMetadata,
  organizationId,
  project,
} from '@circleci/web-ui-data';
import engagementTypeDefs from '~/engagement-experiments/graphql/shim/typeDefs';
import engagementResolvers from '~/engagement-experiments/graphql/shim/resolvers';
import insightsTypeDefs from '~/insights-experiments/graphql/shim/insightsTypeDefs';
import insightsResolvers from '~/insights-experiments/graphql/shim/resolvers';
import projectSettingsTypeDefs from '~/graphql/shim/projectSettingsTypeDef';
import projectSettingsPackagesTypeDefs from '~/project-settings/packages/triggers/graphql/typeDefs';
import onboardingTypeDefs from '~/onboarding/vcs/graphql/shim/typeDefs';
import orgSettingsTypeDefs from '~/org-settings/graphql/typeDefs';
import projectSettingsResolvers from '~/project-settings/graphql/shim/resolvers';
import projectSettingsPackagesResolvers from '~/project-settings/packages/triggers/graphql/resolvers/resolvers';
import onboardingResolvers from '~/onboarding/vcs/graphql/shim/resolvers';
import orgSettingsResolvers from '~/org-settings/graphql/shim/resolvers';
import userSettingsResolvers from '~/user-settings/graphql/shim/resolvers';
import userSettingsTypeDefs from '~/user-settings/graphql/shim/typeDefs';
import plansAndPaymentsResolvers from '~/plans-and-payments/graphql/shim/resolvers';
import plansAndPaymentsTypeDefs from '~/plans-and-payments/graphql/typeDefs';
import * as R from 'ramda';

import resolvers from '~/graphql/shim/resolvers';
import typeDefs from '~/graphql/shim/typeDefs';

const mergeDeepRightAll = (resolversList: any) =>
  resolversList.reduce(R.mergeDeepRight, {});

const schema = makeExecutableSchema({
  resolvers: mergeDeepRightAll([
    resolvers,
    onboardingResolvers,
    organizationId.resolvers,
    orgMetadata.resolvers,
    project.resolvers,
    engagementResolvers,
    me.resolvers,
    insightsResolvers,
    projectSettingsResolvers,
    projectSettingsPackagesResolvers,
    orgSettingsResolvers,
    userSettingsResolvers,
    plansAndPaymentsResolvers,
  ]),
  typeDefs: [
    typeDefs,
    onboardingTypeDefs,
    organizationId.typeDefs,
    orgMetadata.typeDefs,
    engagementTypeDefs,
    me.typeDefs,
    insightsTypeDefs,
    project.typeDefs,
    projectSettingsTypeDefs,
    projectSettingsPackagesTypeDefs,
    orgSettingsTypeDefs,
    userSettingsTypeDefs,
    plansAndPaymentsTypeDefs,
  ],
});

export default schema;
