import { APIOrganization } from '~/plans-and-payments/graphql/shim/fetchers/organizations-rest/organizations-rest';
import { Organization } from '~/plans-and-payments/graphql/types';

export default function (organizations: APIOrganization[]): Organization[] {
  return organizations.map((org: APIOrganization) => ({
    admin: org.admin,
    vcsType: org.vcs_type,
    name: org.login,
  }));
}
