const typeDefs = `
type AdvancedSettings {
  setGithubStatus: Boolean
  buildForkPrs: Boolean
  forksReceiveSecretEnvVars: Boolean
  buildPrsOnly: Boolean
  autoCancelBuilds: Boolean
  oss: Boolean
  setupWorkflows: Boolean
}

type KeyPair {
  access_key_id: String
  secret_access_key: String
}

type ProjectSettings {
  admin: Boolean!
  following: Boolean!
  aws: KeyPair
  canStopBuilds: Boolean!
}

type ProjectPrivacyStatus {
  oss: Boolean!
}

type Owner {
  external_id: Int
}

type ProjectInfoDetails {
  oss: Boolean
  reponame: String
  has_followers: Boolean
  username: String
  analytics_id: String
  has_usable_key: Boolean
  vcs_type: String
  jira: String
  vcs_url: String
  following: Boolean
  default_branch: String
  scopes: [String]
  owner: Owner
}

type ProjectDetails {
  analytics_id: String!
  vcs_type: String!
  reponame: String!
}

type EnvironmentVariable {
  name: String!
  value: String!
  createdAt: String
}

type APIToken {
  label: String
  token: String
  scope: String!
  createdAt: String!
  id: String!
}

type APITokenData {
  createApiToken: APIToken
}

type APITokenResponse {
  data: APITokenData
}

enum AddEnvironmentVariableError {
  BAD_REQUEST
}

type AddEnvironmentVariableResponse {
  errors: [AddEnvironmentVariableError]!
  message: String
}

type CheckoutKey {
  publicKey: String!
  type: String!
  fingerprint: String!
  preferred: Boolean!
  createdAt: String!
}

type Error {
  name: String!
  type: String!
  message: String!
}

type Errors {
  errors: [Error]!
}

type SshKey {
  fingerprint: String!
  hostname: String
}

type JiraIntegrationConnectItem {
  baseUrl: String!
}

type Project2 {
  name: String!
}

type WebhookScope {
  type: String!
  id: String!
}

input WebhookScopeInput {
  type: String!
  id: String!
}

type Webhook {
  id: String!
  name: String!
  scope: WebhookScope!
  events: [String!]!
  url: String!
  verify_tls: Boolean!
  signing_secret: String
  created_at: String
  updated_at: String
}

input WebhookCreateInput {
  name: String!
  scope: WebhookScopeInput!
  events: [String!]!
  url: String!
  verify_tls: Boolean!
  signing_secret: String
}

input WebhookUpdateInput {
  id: String!
  name: String
  scope: WebhookScopeInput
  events: [String!]
  url: String
  verify_tls: Boolean
  signing_secret: String
}

type WebhookEventType {
  name: String!
  type: String!
  description: String!
}

type WebhookPermissions {
  can_view_webhook: Boolean!
  can_manage_webhook: Boolean!
}

type WebhookFeatures {
  supported_event_types: [WebhookEventType!]!
  permissions: WebhookPermissions!
}

extend type Query {
  advancedSettings(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): AdvancedSettings!

  projectDetails(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): ProjectDetails!

  projectSettings(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): ProjectSettings!


  projectPrivacyStatus(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): ProjectPrivacyStatus!

  webhooks(scopeId: String!, scopeType: String!): [Webhook!]!

  webhook(id: String!): Webhook!

  webhookFeatures(scopeId: String!, scopeType: String!): WebhookFeatures!

  environmentVariables(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): [EnvironmentVariable!]!

  apiTokens(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): [APIToken!]!

  checkoutKeys(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): [CheckoutKey!]!

  sshKeys(vcsType: String!, orgName: String!, projectName: String!): [SshKey!]!

  jiraIntegrationConnectItem(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): JiraIntegrationConnectItem

  """
  Note: it was necessary to name this type Project2, as Project was already taken
  by an imported type from web-ui-data
  """
  projects(vcsType: String!, orgName: String!): [Project2!]!
}

extend type Mutation {
  addEnvironmentVariable(
    vcsType: String!
    orgName: String!
    projectName: String!
    name: String!
    value: String!
  ): AddEnvironmentVariableResponse

  deleteEnvironmentVariable(
    vcsType: String!
    orgName: String!
    projectName: String!
    name: String!
  ): String

  importEnvironmentVariables(
    vcsType: String!
    orgName: String!
    projectName: String!
    fromProjectName: String!
    environmentVariableNames: [String!]!
  ): String

  deleteCheckoutKey(
    vcsType: String!
    orgName: String!
    projectName: String!
    fingerprint: String!
  ): String

  stopBuilding(vcsType: String!, orgName: String!, projectName: String!): String

  updateSetGithubStatus(
    vcsType: String!
    orgName: String!
    projectName: String!
    enabled: Boolean!
  ): String

  updateBuildForkPrs(
    vcsType: String!
    orgName: String!
    projectName: String!
    enabled: Boolean!
  ): String

  updateForksReceiveSecretEnvVars(
    vcsType: String!
    orgName: String!
    projectName: String!
    enabled: Boolean!
  ): String

  updateBuildPrsOnly(
    vcsType: String!
    orgName: String!
    projectName: String!
    enabled: Boolean!
  ): String

  updateAutoCancelBuilds(
    vcsType: String!
    orgName: String!
    projectName: String!
    enabled: Boolean!
  ): String

  updateOss(
    vcsType: String!
    orgName: String!
    projectName: String!
    enabled: Boolean!
  ): String

  updateSetupWorkflows(
    vcsType: String!
    orgName: String!
    projectName: String!
    enabled: Boolean!
  ): String

  createApiToken(
    vcsType: String!
    orgName: String!
    projectName: String!
    scope: String!
    label: String
  ): APIToken

  deleteApiToken(
    vcsType: String!
    orgName: String!
    projectName: String!
    token: String!
  ): String

  createDeployKey(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): String

  createUserKey(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): String

  createSshKey(
    vcsType: String!
    orgName: String!
    projectName: String!
    hostname: String!
    privateKey: String!
  ): Errors!

  deleteSshKey(
    vcsType: String!
    orgName: String!
    projectName: String!
    fingerprint: String!
    hostname: String
  ): String

  createJiraToken(
    vcsType: String!
    orgName: String!
    projectName: String!
    jiraToken: String!
  ): String

  deleteJiraToken(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): String

  deleteWebhook(id: String!): String

  deleteAWSVars(
    vcsType: String!
    orgName: String!
    projectName: String!
  ): String

  createWebhook(webhook: WebhookCreateInput!): Webhook

  updateWebhook(webhook: WebhookUpdateInput!): Webhook

  pingWebhook(id: String!): String
}
`;

export default typeDefs;
