import { client } from '@circleci/web-ui-data';

export interface Data {
  enabled: boolean;
}

interface Props {
  orgId?: string;
}

interface isUnregisteredUsersBlockedResponse {
  enabled: boolean;
}

const isUnregisteredUsersBlocked = async ({
  orgId,
}: Props): Promise<isUnregisteredUsersBlockedResponse> => {
  const pathname = `/private/orgs/${orgId}/features/block-unregistered-users`;

  const defaults: isUnregisteredUsersBlockedResponse = {
    enabled: false,
  };

  if (orgId) {
    let response;
    try {
      const rawResponse = await client.apiFetcher({
        fetch,
        pathname,
      });
      response = await rawResponse.json();
    } catch (e) {
      response = {};
    }
    return response || defaults;
  } else {
    return defaults;
  }
};
export default isUnregisteredUsersBlocked;
