const typeDefs = `
scalar Date

input DateTimeRange {
  end: Date!
  start: Date!
}

type PlansAndPaymentsOrganization {
  id: ID
  name: String!
  vcsType: String!
  admin: Boolean
}

input OrganizationInput {
  name: String!
  vcsType: String!
  id: Int
}

type CreditUsage {
  computeCredits: String!
  dlcCredits: Int!
}

type PlanMetric {
  totalCredits: String!
  seconds: Int!
  computeCredits: String!
  dlcCredits: Int
}

type ResourceClassMetric {
  executor: String
  resourceClass: String!
  metric: PlanMetric!
}

type ProjectUsageTable {
  credits: CreditUsage!
  data: DataUsage!
  name: String!
  username: String
  seconds: Int!
  aggregateByResourceClass: [ResourceClassMetric!]
}

type DataObjectUsageTable {
  name: String!
  networkV2: String!
  storageV2: String!
}

type BillingPeriodRange {
  end: Date!
  start: Date!
}

type ThresholdBytes {
  free: String!
  performance: String!
  custom: String!
  scale: String!
}

type NetworkEgress {
  totalBytes: String!
}

type StorageV2 {
  totalBytes: String!
}

type Thresholds {
  network: ThresholdBytes!
  storage: ThresholdBytes!
}

type Leases {
  count: Int!
  leaseCreditsCharged: String!
  overageCreditsCharged: String!
  overageMinutes: Int!
}

type BillingPeriod {
  activeUsers: Int!
  period: BillingPeriodRange!
  projects: [ProjectUsageTable!]!
  dataObjects: [DataObjectUsageTable!]!
  computeCredits: String!
  totalCredits: String!
  totalProjectsUsingDLC: Int!
  totalSeconds: Int!
  totalByResourceClass: [ResourceClassMetric!]
  userCredits: Int!
  networkCredits: String!
  storageCredits: String!
  dlcCredits: Int!
  ipRangesCredits: String!
  networkEgress: NetworkEgress!
  storageV2: StorageV2!
  totalIpRanges: String!
  thresholds: Thresholds!
  leases: Leases!
}

type Usage {
  billingPeriods: [BillingPeriod!]!
}

type PageInfo {
  startOfPageIndex: Int!
  endOfPageIndex: Int!
  hasNextPage: Boolean!
  hasPreviousPage: Boolean!
}

type UsersPage {
  pageInfo: PageInfo!
  totalCount: Int!
  activeUsers: [ActiveUser!]!
}

type IpRanges {
  type: String!
  bytes: String!
  credits: String!
}

type DataUsage {
  storageV2ByProject: String!
  networkV2ByProject: String!
  ipRanges: IpRanges
}

type ActiveUser {
  user: User!
  lastActiveDate: Date!
}

enum PlanTier {
  CONTAINER
  CUSTOM_CONTAINER
  CUSTOM_PERFORMANCE
  FREE
  PERFORMANCE
  GITHUB_MARKETPLACE
  SCALE
}

type UpgradeSuggestions {
  computeCredits: String!
  milliseconds: String!
  userSeats: Int!
}

type SeatComponentUsage {
  consumed: Int!
  paidTotal: Int!
  freeTotal: Int!
}

type CreditComponentUsage {
  available: String!
  used: String!
  privateUsed: Int!
  ossUsed: Int!
  subscription: Int!
}

type ComponentUsage {
  hasPerformanceSeats: Boolean!
  seats: SeatComponentUsage!
  credits: CreditComponentUsage!
}

type BillingPortal {
  href: String!
}

type PlanUsageFeature {
  slug: String!
  quantity: Int!
}

type PlanUsagePlan {
  tier: PlanTier!
  features: [PlanUsageFeature!]!
}

type PlanUsage {
  plan: PlanUsagePlan!
}

enum SubscriptionStatus {
  PAST_DUE
  PENDING
  ACTIVE
  CANCELED
  INCOMPLETE
}

enum CancelReason {
  UNPAID
}

enum SupportTier {
  FREE
  STANDARD
  SILVER
  GOLD
  PLATINUM
  CONTRACTED_STANDARD
  STARTER
  ADVANCED
  PREMIUM
}

type PlanOverviewPlan {
  isTrial: Boolean
  tier: PlanTier!
  trialEnd: String
  subscriptionStatus: SubscriptionStatus
  cancelReason: CancelReason
  supportTier: SupportTier
  billingProvider: String!
}

type CurrentUser {
  id: String!
  selectedEmail: String
  adminOrgs: [PlansAndPaymentsOrganization!]!
}

type APIServicePlan {
  tier: String!
  highWatermark: Int!
  isInactive: Boolean!
  id: String!
  lowWatermark: Int!
  createdAt: String!
}

type PlanOverview {
  currentUser: CurrentUser!
  plan: PlanOverviewPlan!
  upgradeSuggestions: UpgradeSuggestions!
}

type Card {
  billingEmail: String!
  cardExpMonth: Int!
  cardExpYear: Int!
  cardLastFour: String!
  cardType: String!
}

type Invoice {
  id: String!
  currency: String!
  paid: Boolean!
  periodStart: Date!
  periodEnd: Date!
  amountDue: Int!
  date: Int!
}

type PlanShareAndTransfer {
  currentUser: CurrentUser!
  planTier: PlanTier!
}

enum ShareError {
  PAID_PLAN
  UNKNOWN
}

enum TransferError {
  PAID_PLAN
  UNKNOWN
}

type SharePlanResponse {
  error: ShareError
}

type TransferPlanResponse {
  error: TransferError
}

input InitiateUpgradeToUsageInput {
  billingId: String
  clientMutationId: String
  creditPackages: Int
  email: String!
  orgId: String!
  userSeats: Int
}

enum UpgradeErrorType {
  FAILED_PRECONDITION
  UNKNOWN
}

type UpgradeError {
  message: String!
  type: UpgradeErrorType!
}

type UpgradeToUsageResponse {
  upgradeUrl: String
  errors: [UpgradeError!]
}

input SignupInput {
  firstName: String!
  lastName: String!
  email: String!
  phone: String
  orgId: String!
  orgName: String!
  creditPackages: Int!
  supportTier: String!
  chargifyToken: String!
  couponCode: String
  vatNumber: String
}

type SignupPayload {
  success: Boolean!
  errors: [UpgradeError!]
}

type CouponValid {
  valid: Boolean!
}

type UnregisteredUserUsageControlResponse {
  enabled: Boolean!
}

enum CreditType {
  LINUX_OSS
  LINUX_PRIVATE
  OSX_PRIVATE
  OSX_OSS
}

type ScheduledRefill {
  base_credits: Int!
  bonus_credits: Int!
  cost_per_credit_cents: Float!
  credits_cost_cents: Int!
  effective_at: Date!
  provisioned: Boolean!
  total_credits: Int!
}

type MainContract {
  base_credits: Int!
  bonus_credits: Int!
  bonus_refill_credits: Int!
  cents_per_credit: Float!
  credits_cost_cents: Int!
  refill_credits: Int!
  refill_credits_cost_cents: Int!
  support_cost_cents: Int!
  term_end_date: Date!
  term_start_date: Date!
}

type ActiveContracts {
  term_start_date: Date!
  term_end_date: Date!
  main_contract: MainContract!
}

type PlanDetails {
  auto_refillable: Boolean!
  available_credits: Int!
  contract_details: [ActiveContracts]
  refill_amount: Int!
  refill_threshold: Int!
  scheduled_refills: [ScheduledRefill!]!
  support_tier: String!
}

extend type Query {
  activeUsers(
    orgId: String!
    dateRange: DateTimeRange!
    first: Int!
    offset: Int!
  ): UsersPage!
  billingPortal(orgId: String!): BillingPortal!
  MembersCount(vcsType: String!, orgName: String!): Int
  plansAndPaymentsOrganizations: [PlansAndPaymentsOrganization]!
  componentUsage(
    orgId: String!
    dateRange: DateTimeRange!
    planTier: PlanTier!
  ): ComponentUsage!
  usage(orgId: String!, offset: Int!): Usage!
  billingPeriods(orgId: String!): [BillingPeriodRange]!
  plan(orgId: String!): APIServicePlan!
  planUsage(vcsType: String!, orgName: String!): PlanUsage!
  planOverview(vcsType: String!, orgName: String!): PlanOverview!
  planShareAndTransfer(
    vcsType: String!
    orgName: String!
  ): PlanShareAndTransfer!
  couponCodeValid(orgId: String!, couponCode: String!, billingProvider: String!): Boolean!
  enabled(orgId: String!): Boolean!
}

# Return type is arbitrary because GraphQL requires a return type
# API errors are checked and thrown at the network layer
extend type Mutation {
  SharePlan(
    parentOrganizationVcsType: String!
    parentOrganizationName: String!
    piggybackedOrganizations: [OrganizationInput!]!
  ): SharePlanResponse!
  TransferPlan(
    fromOrganizationVcsType: String!
    fromOrganizationName: String!
    toOrganization: OrganizationInput!
  ): TransferPlanResponse!
  UnregisteredUsersUsageControl(
    orgId: String!
    enabled: Boolean!
  ): UnregisteredUserUsageControlResponse
  InitiateUpgradeToUsage(
    initiateUpgradeToUsageInput: InitiateUpgradeToUsageInput!
  ): UpgradeToUsageResponse!
  Signup(signupInput: SignupInput!): SignupPayload!
}
`;

export default typeDefs;
