export interface Identity {
  login: string;
  type: string;
  avatarUrl?: string;
}

export interface APIServiceOrganization {
  id: string;
}

export interface APIServiceUser {
  lastActiveDate: Date;
  node: {
    id: string;
    name?: string;
    avatarURL?: string;
  };
}

export interface APIServiceOrganizationData {
  organization: APIServiceOrganization;
}

export interface APIServiceProjectUsage {
  aggregate: {
    credits: string;
    seconds: number;
    dlcCredits: number;
    computeCredits: string;
    networkV2ByProject: string;
    storageV2ByProject: string;
    ipRanges: string;
  };
  project: {
    name: string;
    username: string;
  };
  aggregateByResourceClass: [
    {
      executor?: string;
      metric: {
        computeCredits: string;
        credits: string;
        seconds: number;
      };
      resourceClass: string;
    },
  ];
  ipRangesMetric: {
    type: string;
    bytes: string;
    credits: string;
  };
}

export interface APIServiceDataObjectUsage {
  name: string;
  metric: {
    storageV2: string;
    networkV2: string;
  };
}

export type NetworkEgress = {
  totalBytes: string;
};

export type StorageV2 = {
  totalBytes: string;
};

export type PlanMetric = {
  credits: string;
  seconds: number;
  computeCredits: string;
  dlcCredits: number;
};

export type ResourceClassMetric = {
  executor?: string;
  resourceClass: string;
  metric: PlanMetric;
};

export interface ThresholdBytes {
  free: string;
  performance: string;
  custom: string;
  scale: string;
}

export interface BillingPeriod {
  period: {
    start: string;
    end: string;
  };
  metrics: {
    activeUsers: {
      totalCount: number;
    };
    projects: {
      totalCount: number;
    };
    total: {
      computeCredits: string;
      credits: string;
      dlcCredits: number;
      seconds: number;
      userCredits: number;
      networkCredits: string;
      storageCredits: string;
      networkEgress: NetworkEgress;
      storageV2: StorageV2;
      ipRanges: string;
      ipRangesCredits: string;
    };
    byProject: {
      nodes: APIServiceProjectUsage[];
      totalByResourceClass: ResourceClassMetric[];
    };
    byDataObject: {
      nodes: APIServiceDataObjectUsage[];
    };
    thresholds: {
      network: ThresholdBytes;
      storage: ThresholdBytes;
    };
    leases: {
      count: number;
      leaseCreditsCharged: string;
      overageCreditsCharged: string;
      overageMinutes: number;
    };
  };
}

export interface APIServiceUsageData {
  plan: {
    billingPeriods: BillingPeriod[];
  };
}

export interface APIServiceActiveUsersData {
  plan: {
    metrics: {
      activeUsers: {
        totalCount: number;
        pageInfo: {
          hasNextPage: boolean;
          endOfPageIndex: number;
          startOfPageIndex: number;
          hasPreviousPage: boolean;
        };
        edges: APIServiceUser[];
      };
    };
  };
}

export interface APIServiceBillingPortalData {
  plan: {
    externalManagementPortalLink: string;
  };
}

export interface ComponentFeature {
  slug: FeatureSlug;
  quantity: number;
  stopDate?: string;
}

export interface APIServiceComponentUsage {
  plan: {
    billingPeriods: [
      {
        metrics: {
          activeUsers: {
            totalCount: number;
          };
          total: {
            credits: string;
          };
        };
      },
    ];
    metrics: {
      total: {
        credits: string;
      };
      activeUsers: {
        totalCount: number;
      };
      OSSLinuxCreditsUsed: { credits: number };
      privateLinuxCreditsUsed: { credits: number };
      OSSOSXCreditsUsed: { credits: number };
      privateOSXCreditsUsed: { credits: number };
    };
    creditsAvailable: string;
    features: ComponentFeature[];
  };
}

export enum FeatureSlug {
  SEATS_PERFORMANCE = 'SEATS_PERFORMANCE',
  FREE_SEATS_PERFORMANCE = 'FREE_SEATS_PERFORMANCE',
  CREDITS = 'CREDITS',
  SUPPORT_TIER_FREE = 'SUPPORT_TIER_FREE',
  SUPPORT_TIER_STANDARD = 'SUPPORT_TIER_STANDARD',
  SUPPORT_TIER_SILVER = 'SUPPORT_TIER_SILVER',
  SUPPORT_TIER_GOLD = 'SUPPORT_TIER_GOLD',
  SUPPORT_TIER_PLATINUM = 'SUPPORT_TIER_PLATINUM',
  SUPPORT_TIER_CONTRACTED_STANDARD = 'SUPPORT_TIER_CONTRACTED_STANDARD',
  SUPPORT_TIER_STARTER = 'SUPPORT_TIER_STARTER',
  SUPPORT_TIER_ADVANCED = 'SUPPORT_TIER_ADVANCED',
  SUPPORT_TIER_PREMIUM = 'SUPPORT_TIER_PREMIUM',
  FLEX_BUY = 'FLEX_BUY',
  TRIAL = 'TRIAL',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  REFILLS_BLOCKED = 'REFILLS_BLOCKED',
}
export interface APIBillingPeriodData {
  plan: {
    billingPeriods: Pick<BillingPeriod, 'period'>[];
  };
}

export interface APICouponCodeValidData {
  plan: {
    couponValid: boolean;
  };
}
