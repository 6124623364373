import fetchActiveUsers, {
  FetchActiveUsers,
} from '~/plans-and-payments/graphql/shim/fetchers/activeUsers/activeUsers';
import fetchBillingPeriod, {
  FetchBillingPeriod,
} from '~/plans-and-payments/graphql/shim/fetchers/billingPeriods/billingPeriods';
import fetchBillingPortal, {
  FetchBillingPortal,
} from '~/plans-and-payments/graphql/shim/fetchers/billingPortal/billingPortal';
import fetchComponentUsage, {
  FetchComponentUsage,
} from '~/plans-and-payments/graphql/shim/fetchers/componentUsage/componentUsage';
import fetchCouponCodeValid, {
  FetchCouponCodeValid,
} from '~/plans-and-payments/graphql/shim/fetchers/couponCode/couponCode';
import fetchMe from '~/plans-and-payments/graphql/shim/fetchers/me/me';
import fetchAPIMembersCount, {
  FetchMembersCount,
} from '~/plans-and-payments/graphql/shim/fetchers/members/membersCount';
import fetchOrganizationsRest from '~/plans-and-payments/graphql/shim/fetchers/organizations-rest/organizations-rest';
import fetchOrganization from '~/plans-and-payments/graphql/shim/fetchers/organizations/organization';
import fetchPlanV2, {
  FetchPlanV2,
} from '~/plans-and-payments/graphql/shim/fetchers/planV2/planV2';
import fetchUsage, {
  FetchUsage,
} from '~/plans-and-payments/graphql/shim/fetchers/usage/usage';
import mapActiveUsers from '~/plans-and-payments/graphql/shim/mappers/activeUsers/activeUsers';
import mapAPIServicePlan from '~/plans-and-payments/graphql/shim/mappers/apiServicePlan/apiServicePlan';
import mapBillingPeriods from '~/plans-and-payments/graphql/shim/mappers/billingPeriods/billingPeriods';
import mapBillingPortal from '~/plans-and-payments/graphql/shim/mappers/billingPortal/billingPortal';
import mapComponentUsage from '~/plans-and-payments/graphql/shim/mappers/componentUsage/componentUsage';
import mapMembersCount from '~/plans-and-payments/graphql/shim/mappers/membersCount/membersCount';
import mapOrganizations from '~/plans-and-payments/graphql/shim/mappers/organization/mapOrganizations';
import mapOrg from '~/plans-and-payments/graphql/shim/mappers/organization/organization';
import mapPlanOverview from '~/plans-and-payments/graphql/shim/mappers/plan/mapPlanOverview';
import mapPlanShareAndTransfer from '~/plans-and-payments/graphql/shim/mappers/plan/mapPlanShareAndTransfer';
import mapPlanUsage from '~/plans-and-payments/graphql/shim/mappers/plan/mapPlanUsage';
import mapSharePlanError from '~/plans-and-payments/graphql/shim/mappers/plan/mapSharePlanError';
import mapTransferPlanError from '~/plans-and-payments/graphql/shim/mappers/plan/mapTransferPlanError';
import mapUpgradeErrors from '~/plans-and-payments/graphql/shim/mappers/upgrade/mapUpgradeErrors';
import mapUsage from '~/plans-and-payments/graphql/shim/mappers/usage/usage';
import initiateUpgrade from '~/plans-and-payments/graphql/shim/mutators/initiateUpgrade';
import performancePlanUpgrade from '~/plans-and-payments/graphql/shim/mutators/performancePlanUpgrade';
import sharePlan from '~/plans-and-payments/graphql/shim/mutators/sharePlan';
import transferPlan from '~/plans-and-payments/graphql/shim/mutators/transferPlan';
import {
  APIServicePlan,
  BillingPeriodRange,
  BillingPortal,
  ComponentUsage,
  MutationInitiateUpgradeToUsageArgs,
  MutationSharePlanArgs,
  MutationSignupArgs,
  MutationTransferPlanArgs,
  MutationUnregisteredUsersUsageControlArgs,
  Organization,
  PlanOverview,
  PlanShareAndTransfer,
  PlanTier,
  PlanUsage,
  QueryorgArgs,
  SharePlanResponse,
  SignupPayload,
  TransferPlanResponse,
  UnregisteredUserUsageControlResponse,
  UpgradeToUsageResponse,
  Usage,
  UsersPage,
} from '~/plans-and-payments/graphql/types';

import fetchApiServicePlan from './fetchers/apiServicePlan/apiServicePlan';
import fetchIsUnregisteredUsersBlocked from './fetchers/isUnregisteredUsersBlocked/isUnregisteredUsersBlocked';
import mapUnregisteredUsersBlockedError from './mappers/isUnregisteredUsersBlocked/mapUnregisteredUsersBlockedError';
import unregisteredUsersUsageControl from './mutators/unregisteredUsersUsageControl';

interface Deps {
  apiKey?: string;
  fetch: typeof window.fetch;
}

export default {
  Query: {
    activeUsers: async (
      _root: any,
      props: FetchActiveUsers,
      _deps: any,
    ): Promise<UsersPage> => {
      const data = await fetchActiveUsers(props);
      return mapActiveUsers(data);
    },

    billingPortal: async (
      _root: any,
      props: FetchBillingPortal,
      _deps: any,
    ): Promise<BillingPortal> => {
      const data = await fetchBillingPortal(props);
      return mapBillingPortal(data);
    },

    componentUsage: async (
      _root: any,
      props: FetchComponentUsage & { planTier: PlanTier },
      _deps: any,
    ): Promise<ComponentUsage> => {
      const data = await fetchComponentUsage(props);
      return mapComponentUsage(data, props.planTier);
    },

    MembersCount: async (_root: any, props: FetchMembersCount, deps: Deps) => {
      const members = await fetchAPIMembersCount({ ...deps, ...props });
      return mapMembersCount(members);
    },

    org: async (
      _root: any,
      props: QueryorgArgs,
      _: Deps,
    ): Promise<Organization> => {
      const [organization] = await Promise.all([fetchOrganization(props)]);

      return mapOrg(props, organization);
    },

    plansAndPaymentsOrganizations: async (
      _root: any,
      _props: any,
      { fetch, apiKey }: Deps,
    ): Promise<Organization[]> => {
      const apiOrganizations = await fetchOrganizationsRest(fetch, apiKey);

      return mapOrganizations(apiOrganizations);
    },

    usage: async (
      _root: any,
      props: FetchUsage,
      _deps: any,
    ): Promise<Usage> => {
      const data = await fetchUsage(props);
      return mapUsage(data);
    },

    plan: async (
      _root: any,
      props: { orgId: string },
      _deps: any,
    ): Promise<APIServicePlan> => {
      const [plan] = await Promise.all([fetchApiServicePlan(props)]);
      return mapAPIServicePlan(plan);
    },

    enabled: async (
      _root: any,
      props: { orgId: string },
      _deps: any,
    ): Promise<boolean> => {
      const response = await fetchIsUnregisteredUsersBlocked(props);
      return response.enabled;
    },

    planOverview: async (
      _root: any,
      props: FetchPlanV2,
      { fetch, apiKey }: Deps,
    ): Promise<PlanOverview> => {
      const [me, planV2, orgs] = await Promise.all([
        fetchMe(fetch, apiKey),
        fetchPlanV2(props),
        fetchOrganizationsRest(fetch, apiKey),
      ]);
      return mapPlanOverview(me, planV2, orgs);
    },

    planShareAndTransfer: async (
      _root: any,
      props: FetchPlanV2,
      { fetch, apiKey }: Deps,
    ): Promise<PlanShareAndTransfer> => {
      const [me, planV2, orgs] = await Promise.all([
        fetchMe(fetch, apiKey),
        fetchPlanV2(props),
        fetchOrganizationsRest(fetch, apiKey),
      ]);

      return mapPlanShareAndTransfer(me, planV2, orgs);
    },

    planUsage: async (_root: any, props: FetchPlanV2): Promise<PlanUsage> => {
      const [planV2] = await Promise.all([fetchPlanV2(props)]);

      return mapPlanUsage(planV2);
    },

    billingPeriods: async (
      _root: any,
      props: FetchBillingPeriod,
      _deps: any,
    ): Promise<BillingPeriodRange[]> => {
      const data = await fetchBillingPeriod(props);

      return mapBillingPeriods(data);
    },

    couponCodeValid: async (
      _root: any,
      props: FetchCouponCodeValid,
      _deps: Deps,
    ): Promise<boolean> => {
      const data = await fetchCouponCodeValid(props);

      return data.plan.couponValid;
    },
  },
  Mutation: {
    SharePlan: async (
      _root: any,
      props: MutationSharePlanArgs,
      deps: Deps,
    ): Promise<SharePlanResponse> => {
      try {
        await sharePlan({
          ...deps,
          ...props,
        });
        return {};
      } catch (e: any) {
        return mapSharePlanError(e);
      }
    },
    TransferPlan: async (
      _root: any,
      props: MutationTransferPlanArgs,
      deps: Deps,
    ): Promise<TransferPlanResponse> => {
      try {
        await transferPlan({
          ...deps,
          ...props,
        });
        return {};
      } catch (e: any) {
        return mapTransferPlanError(e);
      }
    },

    InitiateUpgradeToUsage: async (
      _root: any,
      props: MutationInitiateUpgradeToUsageArgs,
      _deps: any,
    ): Promise<UpgradeToUsageResponse> => {
      try {
        const response = await initiateUpgrade(props);
        return { upgradeUrl: response?.upgradeUrl };
      } catch (e: any) {
        return { errors: mapUpgradeErrors(e) };
      }
    },

    Signup: async (
      _root: any,
      props: MutationSignupArgs,
      _deps: any,
    ): Promise<SignupPayload> => {
      try {
        const response = await performancePlanUpgrade(props);
        return response;
      } catch (e: any) {
        return {
          success: false,
          errors: mapUpgradeErrors(e),
        };
      }
    },

    UnregisteredUsersUsageControl: async (
      _root: any,
      props: MutationUnregisteredUsersUsageControlArgs,
      deps: Deps,
    ): Promise<UnregisteredUserUsageControlResponse> => {
      try {
        const response = await unregisteredUsersUsageControl({
          ...props,
          ...deps,
        });
        return { enabled: response.enabled };
      } catch (e: any) {
        return mapUnregisteredUsersBlockedError(e);
      }
    },
  },
};
