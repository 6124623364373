import { Commit, TriggerParameters, VCSData } from '~/types/types';
import * as R from 'ramda';
import { Pipeline } from '~/graphql/shim/types';
import { VCSType } from '~/graphql/shim/types';
import { getTriggerType } from '../decoupling';

export function vcsAndOrgLacking(
  vcsType: string | undefined,
  orgName: string | undefined,
): boolean {
  return vcsType == undefined || orgName == undefined;
}

export function getVCSData(
  vcs: VCSData | undefined,
  triggerParameters: TriggerParameters,
): VCSData {
  // TODO: this code will need to be changed to handle other trigger adapters eventually
  // Still defaulting to Gitlab for safety.
  // Eventually when non-standalone projects use the triggerParameters too we can replace this.
  const triggerType = getTriggerType(triggerParameters);

  return {
    branch:
      vcs?.branch ||
      R.pathOr<string>('', [triggerType, 'branch'], triggerParameters) ||
      triggerParameters.git?.branch ||
      '',
    tag:
      vcs?.tag || R.pathOr<string>('', [triggerType, 'tag'], triggerParameters),
    revision:
      vcs?.revision ||
      R.pathOr<string>('', [triggerType, 'checkout_sha'], triggerParameters) ||
      triggerParameters.git?.checkout_sha ||
      '',
    origin_repository_url:
      vcs?.origin_repository_url ||
      R.pathOr<string>('', [triggerType, 'web_url'], triggerParameters) ||
      triggerParameters.git?.repo_url ||
      '',
    commit: {
      subject:
        vcs?.commit?.subject ||
        R.pathOr<string>('', [triggerType, 'commit_title'], triggerParameters),
    },
  };
}

// TODO: do not assume vcsType
export function getCommits(pipeline: Pipeline, vcsType: VCSType): Commit[] {
  return [
    {
      vcsRevision: pipeline.vcsRevision || '',
      vcsUrl:
        vcsType === 'github' ||
        (pipeline.vcsUrl && pipeline.vcsUrl.includes('gitlab'))
          ? `${pipeline.vcsUrl}/commit/${pipeline.vcsRevision}`
          : `${pipeline.vcsUrl}/commits/${pipeline.vcsRevision}`,
    },
  ];
}
