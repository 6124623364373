import { useFeatureFlag } from '@circleci/feature-flags';

interface Props {
  orgId: string | undefined;
}

const useBlockUnregisteredUsersFeatureFlagEnabled = ({ orgId }: Props) => {
  const featureFlagData = useFeatureFlag({
    featureName: 'block_unregistered_users',
    attributes: { orgId },
  });

  return featureFlagData.isFeatureEnabled ? true : false;
};

export default useBlockUnregisteredUsersFeatureFlagEnabled;
