import {
  APIOrganization,
  OrganizationPlanSettings,
} from '~/user-settings/graphql/shim/types';

export default ({
  admin,
  avatar_url,
  login,
  name,
  vcs_type,
}: APIOrganization): OrganizationPlanSettings => ({
  admin: admin,
  avatarUrl: avatar_url,
  login: login,
  name: name,
  vcsType: vcs_type,
  shortVcsType: vcs_type === 'bitbucket' ? 'bb' : 'gh',
});
