import {
  APIServiceComponentUsage,
  FeatureSlug,
} from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import { ComponentUsage, PlanTier } from '~/plans-and-payments/graphql/types';

function getCreditsSubscription(props: APIServiceComponentUsage) {
  return (
    props.plan.features.find(
      (feature) => feature.slug === FeatureSlug.CREDITS,
    ) || { quantity: 0 }
  );
}

function hasPerformanceSeatsFeature(props: APIServiceComponentUsage) {
  return Boolean(
    props.plan.features.find(
      (feature) => feature.slug === FeatureSlug.SEATS_PERFORMANCE,
    ),
  );
}

function getPaidSeatsTotal(props: APIServiceComponentUsage) {
  return (
    props.plan.features.find(
      (feature) => feature.slug === FeatureSlug.SEATS_PERFORMANCE,
    ) || { quantity: 0 }
  );
}

function getFreeSeatsTotal(props: APIServiceComponentUsage) {
  return (
    props.plan.features.find(
      (feature) => feature.slug === FeatureSlug.FREE_SEATS_PERFORMANCE,
    ) || { quantity: 0 }
  );
}

function getPrivateCreditsUsed(response: APIServiceComponentUsage): number {
  const { privateLinuxCreditsUsed, privateOSXCreditsUsed } =
    response.plan.metrics;
  return privateLinuxCreditsUsed.credits + privateOSXCreditsUsed.credits;
}
function getOSSCreditsUsed(response: APIServiceComponentUsage): number {
  const { OSSLinuxCreditsUsed, OSSOSXCreditsUsed } = response.plan.metrics;
  return OSSLinuxCreditsUsed.credits + OSSOSXCreditsUsed.credits;
}

export default (
  response: APIServiceComponentUsage,
  planTier: PlanTier,
): ComponentUsage => {
  const currentBillingPeriod = response.plan.billingPeriods[0];
  const creditsAvailable = response.plan.creditsAvailable;

  const { quantity: paidSeatsTotal } = getPaidSeatsTotal(response);
  const { quantity: freeSeatsTotal } = getFreeSeatsTotal(response);
  const { quantity: creditsSubscription } = getCreditsSubscription(response);

  let creditsUsed = currentBillingPeriod.metrics.total.credits;
  let seatsConsumed = currentBillingPeriod.metrics.activeUsers.totalCount;
  if (planTier === PlanTier.FREE) {
    creditsUsed = response.plan.metrics.total.credits;
    seatsConsumed = response.plan.metrics.activeUsers.totalCount;
  }
  return {
    credits: {
      available: creditsAvailable,
      used: creditsUsed,
      privateUsed: getPrivateCreditsUsed(response),
      ossUsed: getOSSCreditsUsed(response),
      subscription: creditsSubscription,
    },
    hasPerformanceSeats: hasPerformanceSeatsFeature(response),
    seats: {
      consumed: seatsConsumed,
      paidTotal: paidSeatsTotal,
      freeTotal: freeSeatsTotal,
    },
  };
};
