import * as React from 'react';

import { Flex, LargeHeading, PlansIcon } from '@circleci/compass';
import { useOrgContext } from '@circleci/web-ui-data';
import { TopNav } from '@circleci/web-ui-nav';
import { css } from '@emotion/core';
import { useRouter } from 'next/router';

import HorizontalTabNav from '~/plans-and-payments/components/HorizontalTabNav';
import { PendingPaymentNotice } from '~/plans-and-payments/components/PendingPaymentNotice';
import { SideNav } from '~/plans-and-payments/components/shared/SideNav';
import { mediaDesktop } from '~/plans-and-payments/components/shared/viewPorts';
import { FeatureSlug } from '~/plans-and-payments/graphql/shim/apiServiceTypes';
import { PlanTier } from '~/plans-and-payments/graphql/types';
import usePlanUsage from '~/plans-and-payments/hooks/planUsage/usePlanUsage';
import useAPIServicePlan from '~/plans-and-payments/hooks/useAPIServicePlan';
import useBlockUnregisteredUsersFeatureFlagEnabled from '~/plans-and-payments/hooks/useBlockUnregisteredUsersFeatureFlag';
import usePlanOverview from '~/plans-and-payments/hooks/usePlanOverview';
import styled from '~/styled';
import { VCSType } from '~/plans-and-payments/types';

import { useMeContext } from '~/contexts/MeContext';
import PageError from './PageError';
import PageLoading from './PageLoading';
import { OverviewHeader } from './planOverviewElements';

export const FlexCont = styled(Flex)`
  flex-direction: column;
  position: relative;
  ${mediaDesktop} {
    flex-direction: row;
  }
`;

const StyledOverviewHeader = styled(OverviewHeader)(({ theme }) => {
  return css`
    justify-content: flex-start;
    margin-bottom: ${theme.space.space24};
  `;
});

const StyledPlansIcon = styled(PlansIcon)(({ theme }) => {
  return css`
    margin-right: ${theme.space.space12};
    margin-bottom: ${theme.space.space2};
  `;
});

const ContentWrapper = styled(Flex)(({ theme }) => {
  return css`
    background-color: ${theme.colors.white};
    flex-direction: column;
    width: 100%;
    padding: ${theme.space.space24};
    ${mediaDesktop} {
      border-top-right-radius: 12px;
    }
  `;
});

const Main = styled.main(({ theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: ${theme.space.space16};
    max-width: 1080px;

    > :not(:last-child) {
      margin-bottom: ${theme.space.space12};
    }

    ${mediaDesktop} {
      padding-top: ${theme.space.space16};

      > :not(:last-child) {
        margin-bottom: ${theme.space.space16};
      }
    }
  `;
});

interface Props {
  children: React.ReactNode;
}

const PageLayout = (props: Props): React.ReactElement => {
  const router = useRouter();
  const { vcsType: vcs, name, orgId: id } = useOrgContext();
  const orgName = name as string;
  const vcsType = vcs as VCSType;
  const orgId = id as string;

  const { me } = useMeContext();

  const {
    planOverview,
    loading: planLoading,
    error: planOverviewError,
  } = usePlanOverview({
    vcsType,
    orgName,
  });

  const {
    plan: planServicePlan,
    loading: planServicePlanLoading,
    error: planServicePlanError,
  } = useAPIServicePlan({
    orgId,
  });

  const isBlockUnregisteredUsersFeatureFlagEnabled =
    useBlockUnregisteredUsersFeatureFlagEnabled({
      orgId,
    });

  const { planUsage } = usePlanUsage({
    vcsType,
    orgName,
  });

  const pageLoading = planLoading || planServicePlanLoading;

  const pageError = planOverviewError || planServicePlanError;

  const legacyContainerPlan = planServicePlan?.id === orgId;

  const showUsageControls =
    isBlockUnregisteredUsersFeatureFlagEnabled &&
    !legacyContainerPlan &&
    (planOverview.plan.tier == PlanTier.PERFORMANCE ||
      planOverview.plan.tier == PlanTier.SCALE ||
      planOverview.plan.tier == PlanTier.CUSTOM_PERFORMANCE);

  const isPendingPayment = !!planUsage.plan.features.find((feature) => {
    return feature.slug === FeatureSlug.PENDING_PAYMENT;
  });

  if (pageLoading) {
    return <PageLoading />;
  }
  if (router.pathname === '/checkout/[vcsType]/[orgName]') {
    return <>{props.children}</>; // had to wrap in a fragment to get the typing to stop complaining
  }
  return (
    <>
      <TopNav me={me} />
      <FlexCont backgroundColor="cg800" paddingX="space8">
        <SideNav />
        <ContentWrapper>
          {isPendingPayment && <PendingPaymentNotice />}
          <StyledOverviewHeader>
            <StyledPlansIcon size={22}></StyledPlansIcon>
            <LargeHeading as="h1">Plan</LargeHeading>
          </StyledOverviewHeader>
          <HorizontalTabNav
            showUsageControls={showUsageControls}
            planTier={planOverview.plan.tier}
          />
          {pageError ? (
            <PageError orgName={orgName} vcsType={vcsType} />
          ) : (
            <Main>{props.children}</Main>
          )}
        </ContentWrapper>
      </FlexCont>
    </>
  );
};

export default PageLayout;
