import {
  APIServicePlanTier,
  APIServicePlanV2,
} from '~/plans-and-payments/graphql/shim/fetchers/planV2/planV2';
import { PlanTier } from '~/plans-and-payments/graphql/types';

export default function mapPlanTier(plan: APIServicePlanV2): PlanTier {
  const apiTier = plan.organization.planV2.tier;
  switch (apiTier) {
    case APIServicePlanTier.CONTAINER:
      return PlanTier.CONTAINER;
    case APIServicePlanTier.CUSTOM_CONTAINER:
      return PlanTier.CUSTOM_CONTAINER;
    case APIServicePlanTier.FREE:
      return PlanTier.FREE;
    case APIServicePlanTier.GITHUB_MARKETPLACE:
      return PlanTier.GITHUB_MARKETPLACE;
    case APIServicePlanTier.PERFORMANCE:
      return PlanTier.PERFORMANCE;
    case APIServicePlanTier.SCALE:
      return PlanTier.SCALE;
    case APIServicePlanTier.CUSTOM_PERFORMANCE:
      return PlanTier.CUSTOM_PERFORMANCE;
  }
}
