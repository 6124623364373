import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APICouponCodeValidData } from '~/plans-and-payments/graphql/shim/apiServiceTypes';

const query = gql`
  query CouponCodeValid($orgId: String!, $couponCode: String!) {
    plan(orgId: $orgId) {
      couponValid(couponCode: $couponCode)
    }
  }
`;

export interface FetchCouponCodeValid {
  orgId: string;
  couponCode: string;
}

export default async (
  props: FetchCouponCodeValid,
): Promise<APICouponCodeValidData> => {
  const response = await apiServiceClient.query<APICouponCodeValidData>({
    query,
    variables: props,
  });

  const defaults: APICouponCodeValidData = {
    plan: {
      couponValid: false,
    },
  };

  return response.data ? response.data : defaults;
};
