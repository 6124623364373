import { useKBar, Priority } from 'kbar';
import router, { useRouter } from 'next/router';
import { useCurrentOrganization } from '~/hooks/misc/useCurrentOrganization';
import { VCSType } from '~/graphql/shim/types';
import { useFollowedProjects } from '~/hooks/decoupling/useFollowedProjects';

export const useProjectsActions = () => {
  const { query } = useRouter();

  const vcsType = query.vcsType as VCSType;
  const orgName = (query.username || query.orgName) as string;
  const { query: kbarQuery } = useKBar();
  const { organization } = useCurrentOrganization({
    vcsType,
    orgName,
    enabled: Boolean(vcsType && orgName),
  });
  const { followedProjects: baseFollowedProjects } = useFollowedProjects({
    organizationId: organization?.id,
  });

  const filtered = organization ? baseFollowedProjects : [];

  const actions = filtered?.map((p) => {
    if (organization?.id === p.username || organization?.name === p.username) {
      return {
        id: `${organization?.id}-${p.project}`,
        name: p.project,
        keywords: p.project,
        section: 'Projects',
        parent: 'currentorganization',
        perform: () =>
          router.push(
            vcsType === 'circleci'
              ? `/pipelines/${vcsType}/${orgName}/${(p as any).id}`
              : `/pipelines/${vcsType}/${orgName}/${p.project}`,
          ),
      };
    } else {
      return {
        id: `${organization?.id}-${p.project}`,
        name: p.project,
        keywords: p.project,
        priority: Priority.LOW,
        parent: 'currentorganization',
        section: 'Followed Projects',
        perform: () =>
          router.push(
            vcsType === 'circleci'
              ? `/pipelines/${vcsType}/${orgName}/${(p as any).id}`
              : `/pipelines/${vcsType}/${orgName}/${p.project}`,
          ),
      };
    }
  });
  kbarQuery.registerActions(actions || []);
};
